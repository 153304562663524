import React from 'react';
import TimePicker from 'react-time-picker';

const GeniusTimePicker = (selectedTime, onChangeTime) => {
  return (
    <div className="flex w-full">
      <TimePicker value={'12:00'} />
    </div>
  );
};

export default GeniusTimePicker;
