import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserType } from '../utility/Redux/profile';
import { setSystemAdminModalStatus } from '../utility/Redux/security';

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const masters = [
    {
      name: 'Default Setting',
      path: '',
      userType: 'MANAGER ADMIN',
      onclick: () => {
        dispatch(setSystemAdminModalStatus(true));
      },
    },
    {
      name: 'Company Master',
      path: '',
      userType: 'ADMIN ',
      onclick: () => {
        navigate('/company_master');
      },
    },
    {
      name: 'User Master',
      path: '',
      userType: 'MANAGER ADMIN',
      onclick: () => {
        navigate('/user_master');
      },
    },

    {
      name: 'Template Master',
      path: '',
      userType: 'ADMIN',
      onclick: () => {
        navigate('/Template_master');
      },
    },
    {
      name: 'User Scope Master',
      path: '',
      userType: 'ADMIN',
      onclick: () => {
        navigate('/User_scope_master');
      },
    },

    {
      name: 'Message Setup Master',
      path: '',
      userType: 'ADMIN',

      onclick: () => {
        navigate('/Message_Setup_Master');
      },
    },
  ];
  const userType = useSelector(selectUserType);
  return (
    <div className="h-full p-4 ">
      <div className="grid grid-cols-2 t:grid-cols-4 d:grid-cols-6 ll:grid-cols-8 gap-3  ">
        {masters.map((e, i) => {
          if (e.userType.includes(userType)) {
            return (
              <Button
                key={i}
                onClick={() => {
                  if (e.path) {
                    navigate(e.path);
                  } else {
                    e.onclick();
                  }
                }}
                variant={'contained'}
                style={{
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                  padding: '0px',
                  borderRadius: '7px',
                  overflow: 'hidden',
                }}
              >
                <div className="bg-[#e9e9e5] py-5 w-full text-brown">
                  {e.name}
                </div>
              </Button>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Settings;
