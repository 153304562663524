export const autoCollectionSaveInBilling = true;
export const licenceExpired_alert =
  'Your license has expired. Please contact support@geniusoffice.com, +91 85589 51962 for renewal. Visit https://www.geniusoffice.com.';
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const mobileRegex = /^[6-9]\d{9}$/;
export const templateForList = [
  'invoice',
  'otp',
  'order_accepted',
  'order_dispatch',
  'delivery_otp',
  'order_delivered',
  'order_rejected',
  'tailoring_pormotional_media',
  'tailoring_pormotional_text',
  'routebilling_pormotional_media',
  'routebilling_pormotional_text',
  'support_msg_text',
  'License expired_text',
  'other',
];
