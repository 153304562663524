import React, { useEffect, useRef, useState } from 'react';

import GeniustextInput from '../../utility/component/mastertext';

import ModalHeaderStyle from '../../utility/component/styles/modalHeader';

import { json, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  setAlertWithTitle,
  setModCompanyHelpStatus,
  setSpinnerLoading,
} from '../../utility/Redux/modal';
import { CallAPIPromise } from '../../routeBilling/menu/dashboard/biiling/modal/comman';
import ModCompanyHelp from '../../utility/component/modals/modCompanyHelp';
import {
  DeleteButton,
  SaveButton,
  UndoButton,
} from '../../utility/component/buttons';
import TemplateMasterHelp from '../../help/templatehelp';
import { templateForList } from '../../utility/constant';
import { toProperCase } from '../../utility/component/format';

const TemplateMaster = () => {
  const formRef = useRef(null);
  const defaultValues = {
    company_email: '',
    template_id: '',
    template_text: '',
    template_for: '',
    template_variable: [], // New template_variable array
  };
  const [err, setErr] = useState('');
  const [data, setData] = useState(defaultValues);
  const [companyHelpStatus, setCompanyHelpStatus] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [templateHelpStats, setTemplateHelpStatus] = useState(false);
  const [newVariable, setNewVariable] = useState(''); // New variable input
  const dispatch = useDispatch();

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll(
      'input, select,textarea'
    );

    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length;
          if (index === 20) {
            inputFields[0].focus();
          } else {
            if (index === 7) {
              inputFields[11].focus();
            } else {
              inputFields[nextIndex].focus();
            }
          }
        }
      });
    });

    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);

  const navigate = useNavigate();
  const onChangeValues = (v) => {
    const { name, value } = v.target;
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSaveData = () => {
    if (data.company_email && data.template_id && data.template_for) {
      dispatch(setSpinnerLoading('Saving'));

      CallAPIPromise('/api/userSettings/messageTemplateMaster', {
        ...data,
        message_type: data.message_type ? data.message_type : 'text',
        template_variable: JSON.stringify(data.template_variable),
      })
        .then((response) => {
          console.log(response);
        })
        .then(() => {
          setData({
            ...defaultValues,
            company_email: data.company_email,
          });
          dispatch(
            setAlertWithTitle({
              title: 'Success',
              msg: 'Data saved successfully',
            })
          );
        })
        .catch(() => {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Some thing went wrong',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
          setDeleteVisible(false);
        });
    } else {
      setErr('All fields are required');

      setTimeout(() => {
        setErr('');
      }, 5000);
    }
  };

  const handleDELETEdata = () => {
    dispatch(setSpinnerLoading('Deleting'));
    CallAPIPromise('/api/userSettings/messageTemplateDelete', {
      ...data,
      template_variable: JSON.stringify(data.template_variable),
    })
      .then((response) => {
        console.log(response);
      })
      .then(() => {
        setData(defaultValues);
        dispatch(
          setAlertWithTitle({
            title: 'Deleted',
            msg: 'Data deleted successfully',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        setDeleteVisible(false);
      });
  };

  const addVariable = () => {
    if (newVariable.trim()) {
      setData((prev) => ({
        ...prev,
        template_variable: [...prev.template_variable, newVariable],
      }));
      setNewVariable('');
    }
  };
  console.log(data);
  return (
    <div
      ref={formRef}
      className="w-full  flex justify-center bg-[#f5f5f7] h-full  px-3"
    >
      <div className="text-sm   rounded-sm   flex flex-col   h-full py-4 ">
        <div>
          <ModalHeaderStyle
            header={'Template Master'}
            onClose={() => {
              navigate('/masters');
            }}
          />
        </div>
        <div
          style={{
            height: `90%`,
          }}
          className="   rounded-b-sm  bg-[#fdfffe] flex flex-col   p-1 "
        >
          <div className="h-[16px] text-red-500 pl-6">{err}</div>
          <div className=" h-full overflow-y-auto overflow-x-hidden  text-black   flex-col l:flex l:flex-row px-4 pb-4">
            <div className="h-fit   l:ml-4 ">
              <div className="border min-w-[420px]  border-[#b5b3b3] grid grid-cols-1 bg-white rounded-sm p-4 mt-2">
                <GeniustextInput
                  title={'Company Email'}
                  type={'text'}
                  grid={true}
                  value={data.company_email}
                  name={'company_email'}
                  search={() => {
                    dispatch(setModCompanyHelpStatus(true));
                    setCompanyHelpStatus(true);
                  }}
                  onChange={onChangeValues}
                />

                <GeniustextInput
                  title={'Template ID'}
                  type={'mobile'}
                  grid={true}
                  search={() => {
                    setTemplateHelpStatus(true);
                  }}
                  value={data.template_id}
                  name={'template_id'}
                  onChange={onChangeValues}
                />
                <div className="flex w-full ">
                  <GeniustextInput
                    title={'Template For'}
                    width={300}
                    // option={templateForList.map((o) => {
                    //   return {
                    //     name: o,
                    //     value: o,
                    //   };
                    // })}
                    type={'text'}
                    value={data.template_for}
                    name={'template_for'}
                    onChange={onChangeValues}
                  />
                  <div className="ml-2 ">
                    <GeniustextInput
                      title={'Message type'}
                      grid={true}
                      option={['Text', 'Media'].map((o) => {
                        return {
                          name: o,
                          value: o,
                        };
                      })}
                      value={data.message_type}
                      name={'message_type'}
                      onChange={onChangeValues}
                    />
                  </div>
                </div>

                <GeniustextInput
                  title={'Template Text'}
                  textArea={true}
                  areaRows={6}
                  grid={true}
                  value={data.template_text}
                  name={'template_text'}
                  onChange={onChangeValues}
                />

                <div className="mt-4 flex">
                  <GeniustextInput
                    title={'New Variable'}
                    type={'text'}
                    width={300}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                        addVariable();
                      }
                    }}
                    value={newVariable}
                    onChange={(e) =>
                      setNewVariable(toProperCase(e.target.value))
                    }
                  />
                  <div className="ml-2 flex  pb-4  items-end">
                    <button
                      className="bg-orange p-2 px-10 rounded-sm"
                      onClick={addVariable}
                    >
                      Add
                    </button>
                  </div>
                </div>

                <div className="mt-4">
                  <h4 className="text-sm font-bold">
                    Added template_variable:
                  </h4>
                  <ul className="list-disc pl-4 mt-2">
                    {data.template_variable.map((variable, index) => (
                      <div className="flex">
                        <li key={index}>{variable}</li>
                        <button
                          onClick={() => {
                            setData((d) => {
                              return {
                                ...d,
                                template_variable:
                                  data.template_variable.filter(
                                    (t) => t !== variable
                                  ),
                              };
                            });
                          }}
                        >
                          <i className=" bi bi-x-lg text-[12px] flex items-center text-red-500 ml-5" />
                        </button>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="mt-2 flex justify-end">
                {deleteVisible && <DeleteButton onClick={handleDELETEdata} />}

                <div className="ml-2">
                  <SaveButton onClick={handleSaveData} />
                </div>

                <div className="ml-1">
                  <UndoButton />
                </div>
              </div>
            </div>
            <ModCompanyHelp
              status={companyHelpStatus}
              onClose={() => {
                setCompanyHelpStatus(false);
              }}
              onRowClick={(parma) => {
                dispatch(setModCompanyHelpStatus(false));
                setData((p) => {
                  return {
                    ...p,
                    company_email: parma.row.company_email,
                  };
                });
              }}
            />
            <TemplateMasterHelp
              status={templateHelpStats}
              onClose={() => {
                setTemplateHelpStatus(false);
              }}
              onRowClick={(param) => {
                setData({
                  ...param.row,
                  template_variable: JSON.parse(param.row.template_variable),
                });
                setDeleteVisible(true);
                setTemplateHelpStatus(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateMaster;
