import React, { useEffect, useRef } from 'react';
import { parseISO } from 'date-fns';
import moment from 'moment';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../utility/Redux/security';
import {
  selectDefultProfileSetting,
  selectPrefix,
  selectUserCode,
  selectUserType,
  selectWidth,
  setIssueSID,
} from '../../utility/Redux/profile';
import {
  selectListStationMasterModalStatus,
  selectNumerickeyboardType,
  setALertStatus,
  setAlertMsg,
  setAlertWithTitle,
  setIssueStockItem,
  setListStationMasterModalStatus,
  setModEmployeeMasterHelpStatus,
  setModListStationMasterStatus,
  setQuantityEditStatus,
  setSpinnerLoading,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
} from '../../utility/Redux/modal';
import ModListStationMaster from '../../utility/component/modals/modListStationMaster';
import { toProperCase } from '../../utility/component/format';
import { itemNameStyle } from './issueStockStyle';
import { Button, Skeleton } from '@mui/material';
import { useFocus } from '../../utility/hooks/hooks';
import { useReactToPrint } from 'react-to-print';
import PrintIssueReport from './printIssueReport';
import PrintIssueMobileReport from './printIssueMobileReport';
import TotalIssueStockFooter from './issueStockfooter';
import StationWiseIssueModal from '../menu/dashboard/biiling/modal/modStationWiseIssueReport';
import AlertModalTwoButton from '../../utility/component/modals/alertWithTwoButton';
import ModExpenseColllection from '../../utility/component/modals/expenseCollection';
import ModDailyExpense from '../../utility/component/modals/ModDailyExpense';
import ModNumericKeyboard from '../menu/dashboard/biiling/modal/modalNumericKeyBoard';
import ModDabit from '../../utility/component/modals/modDebit';
import ModPartyWiseDisount from '../../utility/component/modals/ItemWiseDiscount.js';
import { issueCartIQNTY } from './isuueCartIQNTY.js';
import { issueCartRQNTY } from './issueCartRQNTY.js';
import ModEmployeeMasterHelp from '../../utility/component/modals/modEmployeeMasterHelp.js';
import ItemBox from './itemBox.js';
import CallAPI from '../../utility/functions/getData.js';
import {
  CellEdit,
  ChangeItemRate,
  GetItemsList,
  GetPendingOrder,
  GetSectionName,
} from './functions.js';
import IsssueColumns from './datgrtid.js';
import DrawerFilters from './drawer.js';
import { Close } from '@mui/icons-material';
import database from './firebase.js';
import { child, get, onValue, ref, set, off } from 'firebase/database';
import HandleIndexedDB from '../../utility/functions/indexDbDatabase.js';
import DeleteDatabse from '../../utility/functions/deleteDatabse.js';
import deleteDatabaseStore from '../../utility/functions/deleteDatabse.js';
import deleteWholeDatabase from '../../utility/functions/deleteWholeDatabase.js';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { FormatNumber2 } from '../../utility/component/tools.js';
import IssueDayEndCalculation from './issueDayEndCalculation.js';

const IssueStock = ({
  station_id,
  setStationId,
  stationName,
  setStationNameState,
}) => {
  const [fno, SetFno] = useState();
  const userdefaultSetting = useSelector(selectDefultProfileSetting);
  const [totalDistance, setTotalDistance] = useState();
  const [deiselAmt, setDeiselAmt] = useState();
  const [vehicalAvg, setvehicalAvg] = useState();
  const [calculateReturn, setCalculateReturn] = useState(false);
  const printRef = useRef();
  const thermalPrintRef = useRef();
  const [cash, setCash] = useState('');
  const [startKm, setStartKm] = useState(0);
  const [expense, setExpense] = useState('');
  const [less, setLess] = useState('');
  const [debit, setDebit] = useState('');
  const [due, setDue] = useState('');
  const [collection, setCollection] = useState('');
  const [expenseStatus, setExpenseStatus] = useState(false);
  const [dailyExpenseStatus, setDailyExpenseStatus] = useState(false);
  const [lessStatus, setlessStatus] = useState(false);
  const [pendingOrder, setPendingorder] = useState(false);
  const [bracketLess, setBracketLess] = useState('');
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [cart_item_insert, setCartItemInsert] = useState([]);
  const [cart_item_update, setCartItemUpdate] = useState([]);
  const [loadBulkIssueList, setLoadBulkIssueList] = useState([]);
  const [saveCartList, setSaveCartList] = useState([]);
  const [debitStatus, setDebitStatus] = useState(false);
  const userType = useSelector(selectUserType);
  const [prevBalance, setPrevBalance] = useState('');
  const [stationLess, setStationLess] = useState('');
  const [IspendingOrderUsed, setIsPendingOrderUsed] = useState(false);
  const dispatch = useDispatch();
  const [isIndesDbdataMode, setIsIndexDbDataMode] = useState(false);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [dateFocus, SetDateFocus] = useState(false);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  const [mobile, setMobile] = useState('');
  const [helperName, setHelperName] = useState('');
  const [truckNo, setTruckNo] = useState('');
  const [searchItem, setSearchItem] = useState('');
  const [endKM, setEndKm] = useState(0);
  const [balance, setBalance] = useState('');
  const [oldbalance, setOldBalance] = useState('');
  const [itemLock, setItemLock] = useState(false);
  const [dieselRate, setDeiselRate] = useState(localStorage.getItem('diesel'));
  const numericType = useSelector(selectNumerickeyboardType);
  const [deiselQnty, setDeiselQnty] = useState('');
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [allData, setAllData] = useState([]);

  const stationMasterModalStatus = useSelector(
    selectListStationMasterModalStatus
  );
  const [sName, setSName] = useState('');
  const [isloading, setIsLoading] = useState(true);
  const [editType, setEditType] = useState('');
  const width = useSelector(selectWidth);
  const userCode = useSelector(selectUserCode);

  const edit = (e) => {
    CellEdit(
      e,
      loadBulkIssueList,

      setCartItemUpdate,
      fno,
      date,
      helperName,
      truckNo,
      station_id,
      stationName,
      iTotal,
      rTotal,
      startKm,
      endKM,
      cash,
      less,
      expense,
      collection,
      debit,
      userdefaultSetting,
      issueCartIQNTY,
      setCartItemInsert,
      setRows,
      dispatch,
      setQuantityEditStatus,
      rows,
      issueCartRQNTY,
      setAlertMsg,
      prefix,
      setALertStatus,
      token,
      setStartKm,
      setEndKm,
      setAlertWithTitle,
      ChangeItemRate,
      userCode,
      setAlertTwoButtonModal
    );
  };

  const getfnoForIssuerOption = async () => {
    dispatch(setSpinnerLoading('Finding Fno'));
    CallAPI(
      '/api/issueStockRoute/findCurrentFNO',
      {
        table_prefix: prefix,
        DATE: date,
        SID: station_id,
      },
      token,
      (p) => {
        if (!p.data.error) {
          if (p.data.response[0]) {
            SetFno(p.data.response[0].FNO);
            getCalculation(p.data.response[0].FNO, true);
          } else {
            dispatch(setSpinnerLoading(false));
          }
        }
      },
      dispatch
    );
  };

  const checkIsUnsavedSata = (code, sName, onNotLocalData, res) => {
    let db;
    let database = 'SaveIssueCart';
    const request = indexedDB.open(database);
    request.onsuccess = function (event) {
      // Assign the opened database instance to the db variable
      db = event.target.result;
      if (db.objectStoreNames.length > 0) {
        const transaction = db.transaction(db.objectStoreNames, 'readonly');

        const objectStoreNames = transaction.objectStoreNames;
        db.close();

        const storeNamesArray = Array.from(objectStoreNames);

        const databaseName = database;
        const isHaveUnsavedData = storeNamesArray.filter(
          (e) =>
            e ===
            `${userCode}-${code}-${sName}-${moment(date).format('YYYY-MM-DD')}`
        );
        const storeName = isHaveUnsavedData[0];
        const expiredData = storeNamesArray.filter((e) => {
          const IndBDate = e.split('-');
          const issueDate = moment(
            `${data[3]}-${data[4]}-${IndBDate[5]}`
          ).format('YYYY-MM-DD');
          const issueDateMoment = moment(issueDate, 'YYYY-MM-DD');

          const currentDate = moment();
          const isSameStationData =
            `${data[0]}-${data[1]}-${data[2]}` ===
              `${userCode}-${code}-${sName}` &&
            issueDateMoment.isBefore(currentDate, 'day');
          return isSameStationData;
        });
        expiredData.map((e) => {
          deleteDatabaseStore(database, e);
        });

        const numbers = isHaveUnsavedData[0] ? storeName.split('-') : '';
        const InDbDate = isHaveUnsavedData[0]
          ? `${numbers[3]}-${numbers[4]}-${numbers[5]}`
          : '';
        if (
          isHaveUnsavedData[0] &&
          moment(InDbDate).format('YYYY-MM-DD') ===
            moment().format('YYYY-MM-DD')
        ) {
          HandleIndexedDB({
            databaseName,
            storeName,
            callback: function (data, col) {
              if (data[0]) {
                setAlertTwoButtonModal((e) => {
                  return {
                    ...e,
                    status: true,
                    button1: 'Load',
                    button2: 'Cancel',
                    button1Click: () => {
                      setIsIndexDbDataMode(true);

                      setStationNameState(numbers[2]);
                      setRows(data);
                      setStationId(numbers[1]);
                      dispatch(setIssueSID(numbers[1]));
                      const databaseName = 'Calculations';

                      HandleIndexedDB({
                        databaseName,
                        storeName,
                        callback: function (data, col) {
                          setHelperName(data[0]?.HELPER_NAME);
                          setTruckNo(data[0]?.TRUCK_NO);
                          setStartKm(data[0]?.KM);
                          setEndKm(data[0]?.KM_END);
                          setCash(data[0]?.CASH);
                          setLess(data[0]?.LESS);
                          setExpense(data[0]?.EXPENSE);
                          setCollection(data[0]?.COLLECTION);
                          setDebit(data[0]?.DEBIT);
                          setDeiselQnty(data[0]?.DIESEL_FILLED);
                          setBalance(data[0]?.BALANCE);
                        },
                      });

                      setAlertTwoButtonModal((e) => {
                        return {
                          ...e,
                          status: false,
                        };
                      });
                    },
                    button2Click: () => {
                      DeleteDatabse('SaveIssueCart', storeName);
                      DeleteDatabse('Calculations', storeName);

                      setAlertTwoButtonModal((e) => {
                        return {
                          ...e,
                          status: false,
                        };
                      });
                    },
                    title: 'Alert',
                    msg: 'There is a unsaved data. Would you like to load it',
                  };
                });
              } else {
                onNotLocalData(res);
              }
            },
          });
        } else {
          const isTodayData = storeNamesArray.filter((e) => {
            const store = e.split('-');
            const d = `${store[3]}-${store[4]}-${store[5]}`;
            if (
              moment(d).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
            ) {
              return true;
            } else {
              return false;
            }
          });

          if (!isTodayData[0]) {
            deleteWholeDatabase(database);
            deleteWholeDatabase('Calculations');
          }
          onNotLocalData(res);
        }
      } else {
        db.close();
        onNotLocalData(res);

        const deleteRequest = indexedDB.deleteDatabase(database);

        deleteRequest.onsuccess = function () {
          console.log('Database deleted successfully');
        };

        deleteRequest.onerror = function (event) {
          console.error('Error deleting database:', event.target.error);
        };
        // Delete the database if no object stores are available
      }
    };
    dispatch(setSpinnerLoading(false));

    request.onerror = function (event) {
      console.error('Error opening database:', event.target.error);
      onNotLocalData(res);
    };
  };

  let timeOut;
  const location = useLocation();

  const setStationInIssue = (code, station) => {
    dispatch(setSpinnerLoading('Loading...'));

    clearTimeout(timeOut);
    const dataBaseRef = ref(database, `${prefix}/station/`);
    const connectedRef = ref(database, `${prefix}/station/${code}`);
    console.log(`${prefix}/station/`);
    console.log(code);
    console.log(station_id);

    const setConnectRef = ref(database, `${prefix}/station/${code}/status`);
    const setMsgRef = ref(database, `${prefix}/station/${code}/msg`);
    off(setMsgRef);

    if (!station_id || code !== station_id) {
      console.log('hh');
      get(child(dataBaseRef, `/${code}`))
        .then((sn) => {
          console.log(sn);
          if (sn.exists()) {
            set(setMsgRef, 'IsConnected');
            timeOut = setTimeout(() => {
              dispatch(setSpinnerLoading(false));

              get(child(connectedRef, `/msg`)).then((sn) => {
                if (sn.val()) {
                  const val = sn.val();
                  if (val !== 'rejected') {
                    set(setConnectRef, 'c');
                    set(setMsgRef, 'connected');
                    setStationId(code);
                    dispatch(setIssueSID(code));
                    setStationNameState(station);
                    // checkIsUnsavedSata(code, station);
                    if (location.pathname === '/issue_stock') {
                      onValue(setMsgRef, (sn) => {
                        if (sn.exists()) {
                          const data = sn.val();

                          if (data === 'IsConnected') {
                            set(setMsgRef, `rejected`);
                          }
                        }
                      });
                    }
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: 'Already Connected',
                      })
                    );
                  }
                }
              });
            }, 1000);
          } else {
            set(setConnectRef, 'c');
            set(setMsgRef, 'connected');

            onValue(setMsgRef, (sn) => {
              if (sn.exists()) {
                const data = sn.val();
                if (data === 'IsConnected') {
                  set(setMsgRef, `rejected`);
                }
              }
            });
            setStationId(code);
            dispatch(setSpinnerLoading(false));

            dispatch(setIssueSID(code));
            setStationNameState(station);
            // checkIsUnsavedSata(code, station);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(setSpinnerLoading(false));
    }
  };

  const getFno = async () => {
    const onNotLocalData = (res) => {
      if (selectedOption === 'option1' && station_id) {
        setSaveCartList([]);
        setCartItemInsert([]);
        setCartItemUpdate([]);
        if (res) {
          loadBulkIssue(res.FNO);
        } else {
          loadBulkIssue('');
        }
      } else {
        dispatch(setSpinnerLoading(false));
        setRows([]);
        setSaveCartList([]);
        setCartItemInsert([]);
        setCartItemUpdate([]);
      }
    };
    dispatch(setSpinnerLoading('Finding Fno'));
    CallAPI(
      '/api/issueStockRoute/findCurrentFNO',
      {
        table_prefix: prefix,
        DATE: date,
        SID: station_id,
      },
      token,
      (p) => {
        if (!p.data.error) {
          if (p.data.response[0]) {
            SetFno(p.data.response[0].FNO);
            onNotLocalData(p.data.response[0]);
          } else {
            SetFno('');
            dispatch(setSpinnerLoading(false));

            checkIsUnsavedSata(
              station_id,
              stationName,
              onNotLocalData,
              p.data.response[0]
            );
          }
        }
      },
      dispatch,
      () => {}
    );
  };

  const getStationItemRAte = async (list, sid) => {
    dispatch(setSpinnerLoading('Setting sale price'));

    CallAPI(
      '/api/issueMasterRoute/loadStationItemMaster',
      {
        table_prefix: prefix,
        CODE: sid ? sid : station_id,
      },
      token,
      (p) => {
        if (!p.data.error) {
          let rowList = [];
          const data = p.data.response;
          for (let index = 0; index < list.length; index++) {
            const filterdList = data.filter(
              (e) => e.ICODE === list[index].ICODE
            );
            if (filterdList[0]) {
              rowList.push({
                ...list[index],
                SALE_PRICE: filterdList[0].MRP,
              });
            } else {
              rowList.push({
                ...list[index],
              });
            }
          }
          setRows(rowList);
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };

  const getItemValue = async (it) => {
    const data = {
      table_prefix: prefix,
      DATE: date,
      SID: station_id,
      ICODE: it.ICODE,
    };
    dispatch(setSpinnerLoading('find item if issued'));
    CallAPI(
      '/api/issueStockRoute/findItemIfIssued',
      data,
      token,
      (p) => {
        if (!p.data.error) {
          if (p.data.response[0]) {
            setLoadBulkIssueList((e) => {
              const tQNTY = p.data.response[0].PRV_BAL
                ? p.data.response[0].PRV_BAL + p.data.response[0].IQNTY
                : p.data.response[0].IQNTY;
              if (e.ICODE !== p.data.response[0].ICODE) {
                const item = p.data.response[0];
                const isExistItem = rows.some((i) => i.ICODE === item.ICODE);
                if (
                  moment(date).format('YYYY-MM-DD') !==
                  moment(date).format('YYYY-MM-DD')
                ) {
                  if (isExistItem) {
                    setRows((prevRows) =>
                      prevRows.map((row, i) =>
                        row.ICODE === item.ICODE
                          ? {
                              ...item,
                            }
                          : row
                      )
                    );
                  } else {
                    setRows([
                      ...rows,
                      {
                        ...item,
                      },
                    ]);
                  }
                }

                if (item.IQNTY < 1) {
                  setEditType('IQNTY');
                  dispatch(setnumericKeyboardType('IQNTY'));
                  dispatch(setnumericKeyboardStatus(true));
                  dispatch(
                    setIssueStockItem({
                      title: item.ITEMNAME,
                      id: item.ICODE,
                      value: item.IQNTY,
                    })
                  );
                }
                getstationTotalLess([
                  ...e,
                  {
                    ...p.data.response[0],
                    RQNTY:
                      moment(date).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD') &&
                      p.data.response[0].FNO &&
                      calculateReturn &&
                      p.data.response[0].SQNTY > 0
                        ? tQNTY - p.data.response[0].SQNTY
                        : p.data.response[0].RQNTY,
                    RAMOUNT:
                      moment(date).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD') &&
                      p.data.response[0].FNO &&
                      calculateReturn &&
                      p.data.response[0].SQNTY > 0
                        ? tQNTY -
                          p.data.response[0].SQNTY *
                            p.data.response[0].SALE_PRICE
                        : p.data.response[0].RAMOUNT,
                  },
                ]);
                if (
                  moment(date).format('YYYY-MM-DD') ===
                    moment().format('YYYY-MM-DD') &&
                  p.data.response[0].FNO &&
                  calculateReturn &&
                  p.data.response[0].SQNTY > 0
                ) {
                  setCartItemUpdate((p) => {
                    const f = p.filter((r) => r.ICODE === e.ICODE);
                    const w = p.filter((r) => r.ICODE !== e.ICODE);

                    if (f[0]) {
                      return [
                        ...w,
                        issueCartRQNTY(
                          f[0].IQNTY,
                          f[0].FNO,
                          moment(date).format('YYYY-MM-DD'),
                          helperName,
                          truckNo,
                          station_id,
                          stationName,
                          f[0].ICODE,
                          f[0].ITEMNAME,
                          f[0].SECTION_NAME,
                          f[0].SALE_PRICE,
                          f[0].PRV_BAL ? f[0].PRV_BAL : 0,

                          tQNTY - e.SQNTY,
                          tQNTY - e.SQNTY * e.SALE_PRICE,
                          iTotal,
                          rTotal,
                          startKm,
                          endKM,
                          f[0].REMARKS,
                          cash,
                          less,
                          expense,
                          collection,
                          debit,
                          f[0].SQNTY,
                          f[0].SAMOUNT,
                          userdefaultSetting,
                          f[0].TAX1_RATE,
                          f[0].TAX2_RATE,
                          f[0].TAX3_RATE
                        ),
                      ];
                    } else {
                      return [
                        ...p,
                        issueCartRQNTY(
                          e.IQNTY,
                          e.FNO,
                          moment(date).format('YYYY-MM-DD'),
                          helperName,
                          truckNo,
                          station_id,
                          stationName,
                          e.ICODE,
                          e.ITEMNAME,
                          e.SECTION_NAME,
                          e.SALE_PRICE,
                          e.PRV_BAL ? e.PRV_BAL : 0,

                          tQNTY - e.SQNTY,
                          tQNTY - e.SQNTY * e.SALE_PRICE,
                          iTotal,
                          rTotal,
                          startKm,
                          endKM,
                          e.REMARKS,
                          cash,
                          less,
                          expense,
                          collection,
                          debit,
                          e.SQNTY,
                          e.SAMOUNT,
                          userdefaultSetting,
                          e.TAX1_RATE,
                          e.TAX2_RATE,
                          e.TAX3_RATE
                        ),
                      ];
                    }
                  });
                }
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: 'item already exist in bulkList',
                  })
                );
              }
            });
          } else {
            dispatch(setSpinnerLoading(false));
          }
        }
      },
      dispatch,
      () => {
        setItemLock(false);
      }
    );
  };

  const handleRowSelect = (item) => {
    if (station_id) {
      const l = rows.filter((e) => e.ICODE === item.ICODE);
      if (!itemLock) {
        if (!l[0]) {
          setItemLock(true);
          getItemValue(item);
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Already selected',
            })
          );
        }
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Please select any station to continue.',
        })
      );
    }
  };

  const rTotal = rows.reduce((acc, row) => acc + row.SALE_PRICE * row.RQNTY, 0);
  const iTotal = rows.reduce((acc, row) => acc + row.SALE_PRICE * row.IQNTY, 0);
  const listFilled = [];

  const saveIssueStock = async () => {
    if (saveCartList[0]) {
      dispatch(setSpinnerLoading('Saving data'));
      const TotalRAmount = rows.reduce(
        (total, row) => Number(total) + Number(row.SALE_PRICE * row.RQNTY),
        0
      );
      const TotalIAmount = rows.reduce(
        (total, row) => Number(total) + Number(row.IAMOUNT),
        0
      );
      const TotalSAmount = rows.reduce(
        (total, row) => Number(total) + Number(row.SAMOUNT),
        0
      );
      const list = saveCartList.map((e) => {
        return {
          ...e,
          ITOTAL: TotalIAmount,
          RTOTAL: TotalRAmount,
          STOTAL: TotalSAmount ? TotalSAmount : 0,
        };
      });
      console.log(list);
      CallAPI(
        `/api/issueStockRoute/saveIssueStock`,

        {
          table_prefix: prefix,
          cart_item_insert: list,
        },
        token,
        async function (response) {
          if (!response.data.error) {
            const FNO = response.data.FNO;

            if (selectedOption === 'option2') {
              dispatch(setSpinnerLoading('finding Fno'));

              CallAPI(
                '/api/issueStockRoute/findCurrentFNO',
                {
                  table_prefix: prefix,
                  DATE: date,
                  SID: station_id,
                },
                token,
                (p) => {
                  if (!p.data.error) {
                    if (p.data.response[0]) {
                      SetFno(p.data.response[0].FNO);
                      saveCalculation(p.data.response[0].FNO);
                    }
                  } else {
                    dispatch(setSpinnerLoading(false));
                  }
                },
                dispatch
              );
            } else {
              saveCalculation(FNO);
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 103',
                msg: 'Something Went Wrong!',
              })
            );
          }
        },
        dispatch,
        () => {
          setCartItemInsert([]);
          setCartItemUpdate([]);
          setSaveCartList([]);
          setLoadBulkIssueList([]);
        },
        () => {
          dispatch(setSpinnerLoading(false));
        }
      );
    } else {
      if (oldbalance !== balance) {
        saveCalculation(fno);
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'oops!',
            msg: 'Nothing to save!',
          })
        );
      }
    }
  };

  const modifyIssueStock = async () => {
    // dispatch(setSpinnerLoading('Updating data'));
    const TotalRAmount = rows.reduce(
      (total, row) => Number(total) + Number(row.SALE_PRICE * row.RQNTY),
      0
    );
    const TotalIAmount = rows.reduce(
      (total, row) => Number(total) + Number(row.IAMOUNT),
      0
    );
    const TotalSAmount = rows.reduce(
      (total, row) => Number(total) + Number(row.SAMOUNT),
      0
    );
    const list = cart_item_update.map((e) => {
      return {
        ...e,
        ITOTAL: TotalIAmount,
        RTOTAL: TotalRAmount,
        STOTAL: TotalSAmount ? TotalSAmount : 0,
      };
    });
    const data = { table_prefix: prefix, cart_item_update: list };
    dispatch(setSpinnerLoading('Modifying data'));
    CallAPI(
      `/api/issueStockRoute/modifyIssueStock`,
      data,
      token,
      async function (response) {
        if (response.status === 200) {
          if (cart_item_insert[0]) {
            ModifyiNsertIssueStock();
          } else {
            saveCalculation(fno);
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 107',

              msg: 'Something Went Wrong!',
            })
          );
        }
      },
      dispatch,
      () => {
        setCartItemInsert([]);
        setCartItemUpdate([]);
        setSaveCartList([]);
        setLoadBulkIssueList([]);
        dispatch(setSpinnerLoading(false));
      },
      (e) => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };

  const ModifyiNsertIssueStock = async () => {
    dispatch(setSpinnerLoading('Saving data'));
    const TotalRAmount = rows.reduce(
      (total, row) => Number(total) + Number(row.SALE_PRICE * row.RQNTY),
      0
    );
    const TotalIAmount = rows.reduce(
      (total, row) => Number(total) + Number(row.IAMOUNT),
      0
    );
    const TotalSAmount = rows.reduce(
      (total, row) => Number(total) + Number(row.SAMOUNT),
      0
    );
    const list = cart_item_insert.map((e) => {
      return {
        ...e,
        ITOTAL: TotalIAmount,
        RTOTAL: TotalRAmount,
        STOTAL: TotalSAmount ? TotalSAmount : 0,
      };
    });
    CallAPI(
      `/api/issueStockRoute/saveIssueStockWithFNO`,
      {
        table_prefix: prefix,
        cart_item_insert: list,
      },
      token,
      async function (response) {
        if (!response.data.error) {
          // handlePrint();
          saveCalculation(fno);
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 107',

              msg: 'Something Went Wrong!',
            })
          );
        }
      },
      dispatch,
      () => {
        setCartItemInsert([]);
        setCartItemUpdate([]);
        setSaveCartList([]);
        setLoadBulkIssueList([]);
      },
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };

  const clearState = () => {
    const setMsgRef = ref(database, `${prefix}/station/${station_id}/msg`);
    off(setMsgRef);
    setDate(moment().format('YYYY-MM-DD'));
    setStationNameState('');
    setMobile('');
    setHelperName('');
    setTruckNo('');
    setSearchItem('');
    setSelectedOption('option1');
    setSelectedPrintOption('print');
    setRows([]);
    dispatch(setIssueSID(''));
    setStationId('');

    setSaveCartList([]);
    setCartItemInsert([]);
    setCartItemUpdate([]);
    setCash('');
    setLess('');
    setExpense('');
    setDebit('');
    setCollection('');
    setDue('');
    SetFno('');
    setPendingorder(false);
    setIsPendingOrderUsed(false);
  };

  const [stationIdError, setStationIdError] = useFocus();
  const [stationNameError, setStationNameError] = useFocus();
  const [selectedPrintOption, setSelectedPrintOption] = useState('print');

  const saveissue = () => {
    if (station_id && stationName && rows[0]?.ICODE) {
      if (fno) {
        if (cart_item_update[0]) {
          modifyIssueStock();
        } else {
          if (cart_item_insert[0]) {
            ModifyiNsertIssueStock();
          } else {
            if (oldbalance !== balance) {
              saveCalculation(fno);
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'oops!',
                  msg: 'Nothing to save',
                })
              );
            }
          }
        }
      } else {
        saveIssueStock();
      }
    } else if (!station_id && !stationName) {
      if (!stationName) {
        setStationNameError();
      }
      if (!station_id) {
        setStationIdError();
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Please Select Item For Save Rrecord!',
        })
      );
    }
  };

  const onSaveIssueStock = () => {
    if (station_id && stationName) {
      setAlertTwoButtonModal({
        title: 'Save Data',
        msg: 'Do you want to save this data ?',
        status: true,
        button1: 'Yes',
        button2: 'Cancel',
        button1Click: () => {
          saveissue();
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: false,
          });
        },
        button2Click: () => {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: false,
          });
        },
      });
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Please select any station to continue.',
        })
      );
    }
  };

  const handleUndo = () => {
    clearState();
  };

  const loadIssueFunc = (FNO, response, SID) => {
    if (moment(date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
      setRows(
        response.map((e) => {
          const tQNTY = e.PRV_BAL ? e.PRV_BAL + e.IQNTY : e.IQNTY;
          return {
            ...e,
            RQNTY: e.RQNTY,
            RAMOUNT: e.RAMOUNT,
            IAMOUNT: tQNTY * e.SALE_PRICE,
          };
        })
      );
    }

    setLoadBulkIssueList(
      response.map((e) => {
        const tQNTY = e.PRV_BAL ? e.PRV_BAL + e.IQNTY : e.IQNTY;
        // field: numericType,
        // value: text,
        // id: issueItemDetails.id,
        if (
          moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') &&
          e.FNO &&
          calculateReturn &&
          e.SQNTY > 0
        ) {
          setCartItemUpdate((p) => {
            const f = p.filter((r) => r.ICODE === e.ICODE);
            const w = p.filter((r) => r.ICODE !== e.ICODE);

            if (f[0]) {
              return [
                ...w,
                issueCartRQNTY(
                  f[0].IQNTY,
                  f[0].FNO,
                  moment(date).format('YYYY-MM-DD'),
                  helperName,
                  truckNo,
                  station_id,
                  stationName,
                  f[0].ICODE,
                  f[0].ITEMNAME,
                  f[0].SECTION_NAME,
                  f[0].SALE_PRICE,
                  f[0].PRV_BAL ? f[0].PRV_BAL : 0,

                  tQNTY - e.SQNTY,
                  tQNTY - e.SQNTY * e.SALE_PRICE,
                  iTotal,
                  rTotal,
                  startKm,
                  endKM,
                  f[0].REMARKS,
                  cash,
                  less,
                  expense,
                  collection,
                  debit,
                  f[0].SQNTY,
                  f[0].SAMOUNT,
                  userdefaultSetting,
                  f[0].TAX1_RATE,
                  f[0].TAX2_RATE,
                  f[0].TAX3_RATE
                ),
              ];
            } else {
              return [
                ...p,
                issueCartRQNTY(
                  e.IQNTY,
                  e.FNO,
                  moment(date).format('YYYY-MM-DD'),
                  helperName,
                  truckNo,
                  station_id,
                  stationName,
                  e.ICODE,
                  e.ITEMNAME,
                  e.SECTION_NAME,
                  e.SALE_PRICE,
                  e.PRV_BAL ? e.PRV_BAL : 0,

                  tQNTY - e.SQNTY,
                  tQNTY - e.SQNTY * e.SALE_PRICE,
                  iTotal,
                  rTotal,
                  startKm,
                  endKM,
                  e.REMARKS,
                  cash,
                  less,
                  expense,
                  collection,
                  debit,
                  e.SQNTY,
                  e.SAMOUNT,
                  userdefaultSetting,
                  e.TAX1_RATE,
                  e.TAX2_RATE,
                  e.TAX3_RATE
                ),
              ];
            }
          });
        }
        return {
          ...e,
          RQNTY:
            moment(date).format('YYYY-MM-DD') ===
              moment().format('YYYY-MM-DD') &&
            e.FNO &&
            calculateReturn &&
            e.SQNTY > 0
              ? tQNTY - e.SQNTY
              : e.RQNTY,
          RAMOUNT:
            moment(date).format('YYYY-MM-DD') ===
              moment().format('YYYY-MM-DD') &&
            e.FNO &&
            calculateReturn &&
            e.SQNTY > 0
              ? tQNTY - e.SQNTY * e.SALE_PRICE
              : e.RAMOUNT,
          IAMOUNT: tQNTY * e.SALE_PRICE,
        };
      })
    );
    if (FNO) {
      getCalculation(FNO, false, SID);
    }
    getstationTotalLess(
      response.map((e) => {
        const tQNTY = e.PRV_BAL ? e.PRV_BAL + e.IQNTY : e.IQNTY;
        return {
          ...e,
          RQNTY:
            e.FNO && calculateReturn && e.SQNTY > 0 ? tQNTY - e.SQNTY : e.RQNTY,
          RAMOUNT:
            e.FNO && calculateReturn && e.SQNTY > 0
              ? tQNTY - e.SQNTY * e.SALE_PRICE
              : e.RAMOUNT,
          IAMOUNT: tQNTY * e.SALE_PRICE,
        };
      }),
      SID
    );
  };

  const loadBulkIssue = async (FNO) => {
    const data = {
      table_prefix: prefix,
      DATE: date,
      SID: station_id,
    };
    dispatch(setSpinnerLoading('Loading Bulk Issue'));
    CallAPI(
      '/api/issueStockRoute/loadBulkIssue',
      data,
      token,
      (p) => {
        if (!p.data.error) {
          loadIssueFunc(FNO, p.data.response);
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 105',
              msg: 'Something Went wrong!',
            })
          );
        }
      },
      dispatch,
      () => {
        console.log('j');
      },
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };

  const [selectedOption, setSelectedOption] = useState('option1');

  const getStationBalance = async (icode) => {
    dispatch(setSpinnerLoading('Loading station balance'));
    const data = {
      table_prefix: prefix,
      SID: station_id,
      FDATE: moment(date).format('YYYY-MM-DD'),
    };
    CallAPI(
      'api/issueStockRoute/stationCurrentBalance',
      data,
      token,
      (p) => {
        if (!p.data.error) {
          const data = p.data.response[0];
          const d = data.OP_DEBIT + data.PRV_DEBIT;
          const c = data.OP_CREDIT + data.PRV_CREDIT;
          const prvBal = d - c;
          console.log(d);
          console.log(prvBal);
          setPrevBalance(prvBal);
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };
  const getstationTotalLess = async (list, s) => {
    const sid = s ? s : station_id;
    dispatch(setSpinnerLoading('Loading station less'));
    const data = {
      table_prefix: prefix,
      SID: sid ? sid : station_id,
    };

    CallAPI(
      'api/issueStockRoute/stationTotalLess',
      data,
      token,
      (p) => {
        if (!p.data.error) {
          const data = p.data.response[0];
          setStationLess(data?.DISCOUNT ? data.DISCOUNT : '');
          // const prvBal = data.BTC_AMOUNT - data.AMOUNT_RECEIVED;
          // setPrevBalance(prvBal);
          if (
            moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
          ) {
            getStationItemRAte(list, sid);
          }
        }
      },
      dispatch,
      () => {
        if (
          moment(date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')
        ) {
          dispatch(setSpinnerLoading(false));
        }
      }
    );
  };

  const DeleteItem = async (icode) => {
    setAlertTwoButtonModal({
      ...alertTwoButtonModal,
      status: false,
    });
    const data = {
      table_prefix: prefix,
      DATE: date,
      SID: station_id,
      ICODE: icode,
    };
    CallAPI(
      'api/issueStockRoute/findItemIfBilled',
      data,
      token,
      (p) => {
        if (!p.data.error) {
          if (!p.data.response[0]) {
            dispatch(setSpinnerLoading('Deleting item'));

            CallAPI(
              'api/issueStockRoute/deleteItemInIssueGrid',
              data,
              token,
              (p) => {
                if (!p.data.error) {
                  setRows(rows.filter((i) => i.ICODE !== icode));
                  setCartItemInsert((r) => r.filter((i) => i.ICODE !== icode));
                  setCartItemUpdate((r) => r.filter((i) => i.ICODE !== icode));
                  setSaveCartList((r) => r.filter((i) => i.ICODE !== icode));
                  const databaseName = 'SaveIssueCart';
                  const storeName = `${userCode}-${station_id}-${stationName}`;
                  const deleteItem = icode;
                  HandleIndexedDB({
                    databaseName,
                    storeName,
                    deleteItem,
                  });
                }
              },
              dispatch,
              () => {
                dispatch(setSpinnerLoading(false));
              }
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: `Bill is created for this item you can't delete it.`,
              })
            );
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something went wrong',
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };

  const saveCalculation = async (FNO) => {
    if (FNO) {
      const TotalRAmount = rows.reduce(
        (total, row) => Number(total) + Number(row.SALE_PRICE * row.RQNTY),
        0
      );
      const TotalIAmount = rows.reduce(
        (total, row) => Number(total) + Number(row.IAMOUNT),
        0
      );
      const TotalSAmount = rows.reduce(
        (total, row) => Number(total) + Number(row.SAMOUNT),
        0
      );
      const data = {
        table_prefix: prefix,
        FNO: FNO,
        SID: station_id,
        HELPER_NAME: helperName ? helperName : '',
        TRUCK_NO: truckNo ? truckNo : '',
        ITOTAL: TotalIAmount ? TotalIAmount : 0,
        RTOTAL: TotalRAmount ? TotalRAmount : 0,
        STOTAL: TotalSAmount ? TotalSAmount : 0,

        KM: startKm ? startKm : 0,
        KM_END: endKM ? endKM : 0,
        REMARKS: '',
        CASH: cash ? Number(cash) : 0,
        LESS: less ? Number(less) : 0,
        EXPENSE: expense ? Number(expense) : 0,
        COLLECTION: collection ? Number(collection) : 0,
        DEBIT: debit ? Number(debit) : 0,
        DIESEL_FILLED: deiselQnty ? deiselQnty : 0,
        BALANCE: balance ? Number(balance) : 0,
      };
      dispatch(setSpinnerLoading('Saving Calculation'));
      CallAPI(
        'api/issueStockRoute/updateIssueCalculation',
        data,
        token,
        (p) => {
          if (!p.data.error) {
            if (IspendingOrderUsed) {
              dispatch(setSpinnerLoading('Updating order status'));
              CallAPI(
                '/api/orderBooking/updateOrderStatus',
                {
                  table_prefix: prefix,
                  SID: station_id,
                  FNO: FNO,
                },
                token,
                (e) => {
                  setAlertTwoButtonModal({
                    title: 'Success',
                    msg: 'Data is saved',
                    status: true,
                    button1: 'Ok',
                    button1Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                    },
                  });
                },
                dispatch,
                () => {
                  setPendingorder(false);
                  dispatch(setSpinnerLoading(false));
                }
              );
            } else {
              dispatch(setSpinnerLoading(false));

              setAlertTwoButtonModal({
                title: 'Success',
                msg: 'Data is saved',
                status: true,
                button1: 'Ok',
                button1Click: () => {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: false,
                  });
                },
              });
            }
          }
        },
        dispatch,
        () => {
          const store = `${userCode}-${station_id}-${stationName}-${moment().format(
            'YYYY-MM-DD'
          )}`;
          if (!fno) {
            DeleteDatabse('SaveIssueCart', store);
            DeleteDatabse('Calculations', store);
          }

          clearState();
        },
        () => {
          dispatch(setSpinnerLoading(false));

          dispatch(
            setAlertWithTitle({
              title: 'Failed',
              msg: 'Data is saved but failed to save calculation',
              lottie: 'reject',
            })
          );
        }
      );
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Fno not find for save calculation',
        })
      );
    }
  };

  const getCalculation = async (FNO, isSpinnerClose, SID) => {
    const data = {
      table_prefix: prefix,
      FNO: FNO,
      SID: SID ? SID : station_id,
    };
    dispatch(setSpinnerLoading('Loading Calculation'));
    CallAPI(
      'api/issueStockRoute/loadIssueCalculateion',
      data,
      token,
      (p) => {
        if (!p.data.error) {
          setCash(p.data.response[0].CASH);
          setBalance(p.data.response[0].BALANCE);
          setHelperName(p.data.response[0].HELPER_NAME);
          setEndKm(p.data.response[0].KM_END);
          setStartKm(p.data.response[0].KM);
          setLess(p.data.response[0].LESS);
          setTruckNo(p.data.response[0].TRUCK_NO);
          setDeiselQnty(p.data.response[0].DIESEL_FILLED);

          setCollection(p.data.response[0].COLLECTION);
          setDebit(p.data.response[0].DEBIT);

          setExpense(p.data.response[0].EXPENSE);
          // ReturnCalc();
        }
      },
      dispatch,
      () => {
        if (isSpinnerClose) {
          dispatch(setSpinnerLoading(false));
        }
      }
    );
  };

  const OkButton = () => {
    if (!stationMasterModalStatus) {
      if (station_id) {
        setRows([]);

        setCartItemInsert([]);
        setCartItemUpdate([]);
        setSaveCartList([]);
        setLoadBulkIssueList([]);
        setCash('');
        setExpense('');
        setDebit('');
        setLess('');
        setCollection('');
        setDue('');
        setBalance('');
        setPrevBalance('');
        getFno();
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Please select station to continue.',
          })
        );
      }
    }
  };

  const columns = IsssueColumns(
    width,
    userType,
    setAlertTwoButtonModal,
    DeleteItem,
    alertTwoButtonModal
  );

  const listItemMasterStatus = useSelector(selectListStationMasterModalStatus);

  useEffect(() => {
    if (!isIndesDbdataMode) {
      if (!station_id) {
        setRows([]);

        setSaveCartList([]);
        setCartItemInsert([]);
        setCartItemUpdate([]);
        setLoadBulkIssueList([]);
      }
      if (selectedOption === 'option2') {
        setRows([]);
        setSaveCartList([]);
        setCartItemInsert([]);
        setCartItemUpdate([]);
        setLoadBulkIssueList([]);
        getfnoForIssuerOption();
      } else {
        setRows([]);
        setSaveCartList([]);
        setCartItemInsert([]);
        setCartItemUpdate([]);
        setLoadBulkIssueList([]);
      }
    } else {
      setIsIndexDbDataMode(false);
    }
  }, [station_id, listItemMasterStatus, selectedOption]);

  useEffect(() => {
    if (selectedOption === 'option2' && !data[0]) {
      GetSectionName(
        prefix,
        token,
        setData,
        setIsLoading,
        GetItemsList,
        dispatch,
        setItems,
        setAllData
      );
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption === 'option2' && !items[0]) {
      GetItemsList(
        sName,
        dispatch,
        prefix,
        setItems,
        setIsLoading,
        setAllData,
        token
      );
    }
  }, [sName, selectedOption]);

  useEffect(() => {
    rows?.map((row) => {
      // if (row.IQNTY > 0 || row.PRV_BAL > 0 || row.RQNTY > 0) {
      listFilled.push(row);
      // }
    });

    listFilled.map((row) => {
      const isRemoveAbleItem = saveCartList.filter(
        (i) => i.ICODE === row.ICODE && row.IQNTY === 0 && i.IQNTY !== 0
      );
      if (
        row.IQNTY > 0 ||
        row.PRV_BAL > 0 ||
        row.RQNTY > 0 ||
        isRemoveAbleItem[0]
      ) {
        setSaveCartList((p) => {
          const f = p.filter((ite) => ite.ICODE === row.ICODE);
          const fi = p.filter((it) => it.ICODE !== row.ICODE);

          if (f[0]) {
            return [
              ...fi,
              issueCartIQNTY(
                row.IQNTY,
                '',
                moment(date).format('YYYY-MM-DD'),
                helperName,
                truckNo,
                station_id,
                stationName,
                row.ICODE,
                row.ITEMNAME,
                row.SECTION_NAME,
                row.SALE_PRICE,
                row.PRV_BAL ? row.PRV_BAL : 0,

                row.RQNTY,
                row.RQNTY * row.SALE_PRICE,
                iTotal,
                rTotal,
                startKm,
                endKM,
                '',
                cash,
                less,
                expense,
                collection,
                debit,
                row.SQNTY,
                row.SAMOUNT,
                userdefaultSetting,
                row.TAX1_RATE,
                row.TAX2_RATE,
                row.TAX3_RATE
              ),
            ];
          } else {
            return [
              ...p,
              issueCartIQNTY(
                row.IQNTY,
                '',
                moment(date).format('YYYY-MM-DD'),
                helperName,
                truckNo,
                station_id,
                stationName,
                row.ICODE,
                row.ITEMNAME,
                row.SECTION_NAME,
                row.SALE_PRICE,
                row.PRV_BAL ? row.PRV_BAL : 0,

                row.RQNTY,
                row.RQNTY * row.SALE_PRICE,
                iTotal,
                rTotal,
                startKm,
                endKM,
                '',
                cash,
                less,
                expense,
                collection,
                debit,
                row.SQNTY,
                row.SAMOUNT,
                userdefaultSetting,
                row.TAX1_RATE,
                row.TAX2_RATE,
                row.TAX3_RATE
              ),
            ];
          }
        });
      }
    });
  }, [rows]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePrint = useReactToPrint({
    content: () =>
      selectedPrintOption === 'print'
        ? printRef.current
        : thermalPrintRef.current,
    onAfterPrint: () => {
      clearState();
    },
  });

  useEffect(() => {
    if (searchItem) {
      const regex = /\d+/;

      const searchItems = allData.filter((item) => {
        if (regex.test(searchItem.substring(0, 1))) {
          return item.ICODE.toString().includes(searchItem);
        } else {
          return item.ITEMNAME.toLowerCase().includes(searchItem.toLowerCase());
        }
      });

      setItems(searchItems);
    } else {
      setItems(allData);
    }
  }, [searchItem]);

  useEffect(() => {
    const handleOffline = () => {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Internet not connected.',
        })
      );
    };

    if (navigator.onLine) {
      window.addEventListener('offline', handleOffline);
      return () => window.removeEventListener('offline', handleOffline);
    } else {
      handleOffline();
    }
  }, [navigator.onLine]);

  useEffect(() => {
    if (station_id && stationName && !fno) {
      const data = [
        {
          SID: station_id,
          HELPER_NAME: helperName ? helperName : '',
          TRUCK_NO: truckNo ? truckNo : '',

          KM: startKm ? startKm : 0,
          KM_END: endKM ? endKM : 0,
          REMARKS: '',
          CASH: cash ? Number(cash) : 0,
          LESS: less ? Number(less) : 0,
          EXPENSE: expense ? Number(expense) : 0,
          COLLECTION: collection ? Number(collection) : 0,
          DEBIT: debit ? Number(debit) : 0,
          DIESEL_FILLED: deiselQnty ? deiselQnty : 0,
          BALANCE: balance ? Number(balance) : 0,
          ICODE: 1,
        },
      ];

      const databaseName = 'Calculations';
      const storeName = `${userCode}-${station_id}-${stationName}-${moment().format(
        'YYYY-MM-DD'
      )}`;
      const dataToSave = data;
      HandleIndexedDB({
        databaseName,
        storeName,
        dataToSave,
      });
    }
  }, [
    station_id,
    helperName,
    truckNo,
    startKm,
    endKM,
    '',
    cash,
    less,
    expense,
    collection,
    debit,
    deiselQnty,
    balance,
  ]);

  return (
    <div id="container" className="w-full h-full bg-white p-4 ">
      <div id="main" className="w-full h-full flex flex-col   ">
        <div id="header" className="flex mb-2">
          <div
            style={
              window.innerWidth <= 500
                ? {
                    width: '100%',

                    borderBottom: '0px ',
                    border: '1px solid #b5b3b3',
                  }
                : window.innerWidth <= 768
                ? { width: '35%', border: '1px solid #b5b3b3' }
                : window.innerWidth <= 1280
                ? { width: '25%', border: '1px solid #b5b3b3' }
                : { width: '20%', border: '1px solid #b5b3b3' }
            }
            className="flex items-center   rounded-l-sm max-sm:rounded-l-none px-2 max-sm : text-sm t:text-xs xl:text-base max-sm:rounded-t-sm "
          >
            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className="w-1/2 l:w-fit flex rounded-sm  overflow-hidden"
            >
              <div
                style={{
                  padding: '2px',
                  borderRight: '1px solid #a6a2a2 ',
                }}
                className="bg-[#f1efeb] overflow-hidden w-full flex items-center "
              >
                <DatePicker
                  className=" bg-transparent flex items-center text-sm w-full outline-0 overflow-hidden  "
                  dateFormat=" d MMMM , yyyy "
                  selected={parseISO(moment(date).format('YYYY-MM-DD'))}
                  onChange={
                    userType === 'MANAGER'
                      ? (e) => {
                          const formattedDate = moment(e).format('YYYY-MM-DD');

                          if (moment().isSameOrAfter(formattedDate)) {
                            setDate(moment(e).format('YYYY-MM-DD'));
                            SetDateFocus(false);
                          } else {
                            SetDateFocus(false);
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert',
                                msg: 'Invalid date',
                              })
                            );
                          }
                        }
                      : ''
                  }
                  readOnly={true}
                  onClickOutside={() => SetDateFocus(false)}
                  open={dateFocus}
                />
              </div>

              <div
                style={{
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)',
                }}
                className="flex items-center  bg-[#f7dda4] rounded-r-sm "
              >
                <i
                  className="bi bi-calendar2 py-1 px-2"
                  onClick={
                    userType === 'MANAGER' ? () => SetDateFocus(true) : null
                  }
                  style={{ fontSize: '14px' }}
                ></i>
              </div>
            </div>
            <div className="flex grow">
              <Button
                // ref={updateRef}
                data-tooltip-id="tip"
                data-tooltip-content="Load bulk issue"
                onClick={OkButton}
                variant="contained"
                style={{
                  padding: '0px',
                  textTransform: 'none',
                  letterSpacing: '1px',
                  backgroundColor: '#c1c1c1',
                  marginLeft: 4,
                  width: '100%',
                }}
              >
                <div className="text-black font-sans py-1  px-3 h-full ">
                  Ok{' '}
                </div>
              </Button>
            </div>
          </div>

          <div
            style={
              window.innerWidth <= 500
                ? {
                    display: 'none',
                    visibility: ' hidden',
                  }
                : window.innerWidth <= 768
                ? { width: '30%' }
                : window.innerWidth <= 1280
                ? { width: '50%' }
                : { width: '55%' }
            }
            className="block max-sm:text-xs t:text-xs xl:text-base"
          >
            <div className="flex">
              <div
                style={{
                  border: '1px solid #b5b3b3',
                  borderLeft: '0px',
                }}
                className="flex w-1/4 text-sm t:text-t lg:text-sm items-center pl-2 t:pl-1 "
              >
                <div>
                  <button>
                    <SearchIcon
                      onClick={() => {
                        dispatch(setListStationMasterModalStatus(true));

                        setRows([]);
                        setSaveCartList([]);
                        setCartItemInsert([]);
                        setCartItemUpdate([]);
                        setCash('');
                        setExpense('');
                        setDebit('');
                        setBalance('');
                        setLess('');
                        setCollection('');
                        setDue('');
                      }}
                      style={{ color: '#f44336', fontSize: '18px' }}
                    />
                  </button>
                </div>
                <div className="text-[#4a4a4a] py-2">Station</div>
              </div>
              <input
                ref={stationIdError}
                value={station_id}
                onChange={() => {
                  dispatch(setModListStationMasterStatus(true));
                }}
                style={{
                  borderRadius: '0px',
                  border: '1px solid #b5b3b3',
                  borderLeft: '0px',
                  borderRight: '0px',
                }}
                type="number"
                className="w-1/4 text-sm  pl-2   focus:outline-none "
                placeholder="Required"
              />

              <input
                ref={stationNameError}
                value={stationName}
                onChange={(e) => {
                  dispatch(setModListStationMasterStatus(true));
                }}
                style={{
                  width: '25%',
                  borderRadius: '0px',
                  border: '1px solid #b5b3b3',
                }}
                type="text"
                className="w-1/3 text-sm t:text-t lg:text-sm pl-2   focus:outline-none border-r-0"
                placeholder="Required"
              />
              <input
                ref={stationNameError}
                value={deiselQnty}
                onChange={(e) => {
                  setDeiselQnty(e.target.value);
                }}
                style={{
                  width: '25%',
                  borderRadius: '0px',
                  borderTop: '1px solid #b5b3b3',
                  borderBottom: '1px solid #b5b3b3',
                  borderRight: '1px solid #b5b3b3',
                }}
                type="text"
                className="w-1/3 text-sm t:text-t lg:text-sm pl-2   focus:outline-none border-l-0"
                placeholder="Diesel Quantity"
              />
            </div>
            <div className="flex">
              <div
                style={{
                  border: '1px solid #b5b3b3',
                  borderLeft: '0px',
                  borderTop: '0px',
                }}
                className="w-1/4 flex text-sm py-2  items-center pl-2  text-[#4a4a4a] "
              >
                Mobile
              </div>
              <input
                value={mobile}
                onChange={(e) => {
                  const inputLength = e.target.value.length;
                  if (inputLength <= 11) {
                    setMobile(e.target.value);
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Mobile Error',
                        msg: 'Please type Valid Mobile',
                      })
                    );
                  }
                }}
                style={
                  window.innerWidth === 768
                    ? {
                        width: '100%',
                        borderBottom: '1px solid #b5b3b3',
                        borderRight: '1px solid #b5b3b3',
                        borderRadius: '0px',
                      }
                    : {
                        width: '25%',
                        borderRadius: '0px',
                        color: 'red',
                        borderBottom: '1px solid #b5b3b3',
                      }
                }
                type="number"
                className="text-sm  pl-2 focus:outline-none "
                placeholder="Enter Mobile"
              />
              <div
                style={
                  window.innerWidth === 768
                    ? { display: 'none' }
                    : {
                        borderRadius: '0px',
                        border: '1px solid #b5b3b3',
                        borderTop: '0px',
                        display: 'flex',
                      }
                }
                className="flex grow justify-between d items-center"
              >
                <div className="flex justify-end   h-full ">
                  <button
                    style={{
                      textTransform: 'none',
                      letterSpacing: '1px',
                      backgroundColor:
                        !station_id || fno || !rows[0] || pendingOrder
                          ? '#e6e6e3'
                          : '#f7dda4',
                      color: 'black',
                      height: '100%',
                      opacity:
                        !station_id || fno || !rows[0] || pendingOrder
                          ? 0.6
                          : 1,
                    }}
                    disabled={!station_id || fno || !rows[0] || pendingOrder}
                    className="px-3 text-sm"
                    onClick={() => {
                      dispatch(setSpinnerLoading('finding items'));
                      GetPendingOrder(
                        {
                          table_prefix: prefix,
                          DATE: moment(date).format('YYYY-MM-DD'),
                          SID: station_id,
                        },
                        token,
                        (e) => {
                          if (!e.data.error) {
                            console.log(e.data);
                            const data = e.data.response;
                            if (data[0]) {
                              let rowList = [];

                              for (
                                let index = 0;
                                index < rows.length;
                                index++
                              ) {
                                const filterdList = data.filter(
                                  (e) => e.ICODE === rows[index].ICODE
                                );
                                if (filterdList[0]) {
                                  rowList.push({
                                    ...rows[index],
                                    IQNTY: filterdList[0].ORDER_QNTY,
                                  });
                                } else {
                                  rowList.push({
                                    ...rows[index],
                                  });
                                }
                              }
                              setRows(rowList);
                              setPendingorder(true);
                              setIsPendingOrderUsed(true);
                            } else {
                              dispatch(setSpinnerLoading(false));
                              dispatch(
                                setAlertWithTitle({
                                  title: 'Alert',
                                  msg: 'No Item found',
                                })
                              );
                            }
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Error',
                                msg: 'Invalid Module',
                              })
                            );
                          }
                        },
                        dispatch,
                        () => {
                          dispatch(setSpinnerLoading(false));
                        }
                      );
                    }}
                  >
                    Check Order{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            style={
              window.innerWidth <= 500
                ? {
                    width: '100%',
                  }
                : window.innerWidth <= 768
                ? { width: '35%' }
                : window.innerWidth <= 1280
                ? { width: '25%' }
                : { width: '25%' }
            }
            className="block max-sm:text-xs  t:text-xs xl:text-base"
          >
            <div className="flex">
              <div
                style={{
                  width: '50%',
                  border: '1px solid #b5b3b3',
                  borderLeft: '0px solid #b5b3b3',
                  color: '#4a4a4a',
                }}
                className=" flex text-sm items-center pl-2 t:pl-1 "
              >
                <div>
                  <button
                    onClick={() => {
                      dispatch(setModEmployeeMasterHelpStatus(true));
                    }}
                  >
                    <SearchIcon
                      style={{ color: '#f44336', fontSize: '18px' }}
                    />
                  </button>
                </div>
                <div className="text-[#4a4a4a] py-2">Helper Name</div>
              </div>
              <div
                onClick={() => {
                  dispatch(setModEmployeeMasterHelpStatus(true));
                }}
                style={
                  window.innerWidth < 500
                    ? {
                        width: '30%',
                        border: '1px solid #b5b3b3',
                        borderLeft: '0px',
                        color: '#4a4a4a',
                      }
                    : {
                        width: '50%',
                        border: '1px solid #b5b3b3',
                        borderLeft: '0px',
                        color: '#4a4a4a',
                      }
                }
                className="pl-2  rounded-tr-sm text-[#4a4a4a]  items-center flex focus:outline-none "
              >
                {' '}
                {helperName}{' '}
              </div>
            </div>
            <div className="flex">
              <div
                style={
                  window.innerWidth < 500
                    ? {
                        width: '40%',
                        border: '1px solid #b5b3b3',
                        color: '#4a4a4a',
                        borderTop: '0px',
                      }
                    : {
                        width: '50%',
                        border: '1px solid #b5b3b3',
                        borderLeft: '0px',
                        color: '#4a4a4a',
                        borderTop: '0px',
                      }
                }
                className="flex text-sm py-2 items-center pl-2 text-[#4a4a4a]"
              >
                <input
                  value={truckNo}
                  onChange={(e) => {
                    setTruckNo(e.target.value);
                  }}
                  type="text"
                  placeholder="Truck No."
                  className=" text-sm w-[50%] t:text-t lg:text-sm  focus:outline-none   t:rounded-t-none t:rounded-r-sm t:rounded-l-none"
                />
              </div>
              <div
                style={
                  window.innerWidth < 500
                    ? {
                        width: '30%',
                        border: '1px solid #b5b3b3',
                        borderLeft: '0px',
                        borderTop: '0px',
                        color: '#4a4a4a',
                      }
                    : {
                        width: '50%',
                        borderBottomRightRadius: '7px',
                        border: '1px solid #b5b3b3',
                        borderLeft: '0px',
                        color: '#4a4a4a',
                        borderTop: '0px',
                      }
                }
                className="flex"
              ></div>

              <div
                style={
                  window.innerWidth > 500
                    ? { display: 'none' }
                    : {
                        width: '30%',
                        border: '1px solid #b5b3b3',
                        borderLeft: '0px',
                        borderTop: '0px',
                      }
                }
                className="flex justify-center items-center"
              >
                <div className="text-xs font-semibold text-[#4a4a4a]">
                  Issue
                </div>
                <input
                  type="radio"
                  value="option2"
                  checked={selectedOption === 'option2'}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  className="ml-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border w-full p-1 rounded-t-sm flex items-center  justify-between">
          <div className="flex">
            <div className="flex  justify-start pl-2">
              <input
                type="radio"
                value="option1"
                checked={selectedOption === 'option1'}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="mr-1 "
              />
              <div className="text-sm   text-[#4a4a4a]">Bulk Issue</div>
            </div>
            <div className="flex justify-start ml-4">
              <input
                type="radio"
                value="option2"
                checked={selectedOption === 'option2'}
                onChange={(e) => {
                  if (station_id) {
                    setIsDrawerOpen(e.target.checked);

                    setSelectedOption(e.target.value);
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: 'Select station first',
                      })
                    );
                  }
                }}
                className="mr-1"
              />
              <div className="text-sm  text-[#4a4a4a]">Issue</div>
            </div>
            <div className="flex justify-start ml-4">
              <input
                type="checkbox"
                value={calculateReturn}
                checked={calculateReturn}
                onChange={(e) => {
                  setCalculateReturn(e.target.checked);
                }}
                className="mr-1"
              />
              <div className="text-sm  text-red-500">Return</div>
            </div>
          </div>
          <div className="flex">
            <div className="ml-4 flex text-sm items-center">
              <div>Diesel Rate :</div>
              <input
                value={dieselRate}
                onChange={(e) => {
                  setDeiselRate(e.target.value);
                  localStorage.setItem('diesel', e.target.value);
                }}
                type="number"
                className=" text-sm bg-[#f1efeb] max-w-[120px] pl-1 ml-2 border t:text-t lg:text-sm  focus:outline-none   rounded-sm"
              />
            </div>
            <div className="flex itesm-center text-sm ml-2">
              <div>Start Km :</div>
              <input
                value={startKm}
                onChange={(e) => {
                  if (station_id) {
                    setStartKm(e.target.value);
                  }
                }}
                onClick={() => {
                  if (station_id) {
                    dispatch(setnumericKeyboardType('START_KM'));
                    dispatch(setnumericKeyboardStatus(true));
                    dispatch(
                      setIssueStockItem({
                        title: 'Start Km',
                        id: '1',
                        value: startKm,
                      })
                    );
                  }
                }}
                placeholder="Start KM"
                type="number"
                className=" text-sm border max-w-[120px] rounded-sm bg-[#f1efeb] pl-1  focus:outline-none   ml-2"
              />
            </div>
            <div className="flex test-sm items-center ml-2">
              <div className="text-sm">End Km :</div>
              <input
                value={endKM}
                onChange={(e) => {
                  if (station_id) {
                    setEndKm(e.target.value);
                  }
                }}
                onClick={() => {
                  if (station_id) {
                    dispatch(setnumericKeyboardType('END_KM'));
                    dispatch(setnumericKeyboardStatus(true));
                    dispatch(
                      setIssueStockItem({
                        title: 'End Km',
                        id: '1',
                        value: endKM,
                      })
                    );
                  }
                }}
                type="number"
                className=" text-sm border max-w-[120px] rounded-sm bg-[#f1efeb] pl-1  focus:outline-none   ml-2"
              />
            </div>
          </div>
        </div>

        <div
          id="datagrid"
          className="w-full   flex  flex-col grow  rounded-sm overflow-hidden  "
        >
          <div className="flex h-full w-full ">
            <div className="h-full w-full">
              <div
                style={{ width: '100%' }}
                className="h-full flex flex-col justify-between"
              >
                <div
                  style={
                    window.innerWidth < 500
                      ? { width: '100%', height: '78%' }
                      : window.innerWidth <= 1024
                      ? { height: '78%', width: '100%' }
                      : window.innerWidth <= 1280
                      ? { height: '78%', width: '100%' }
                      : { width: '100%', height: '83%' }
                  }
                >
                  <DataGrid
                    hideFooterPagination
                    hideFooter
                    headerHeight={0}
                    rowHeight={35}
                    showCellRightBorder={true}
                    showColumnRightBorder={true}
                    disableColumnMenu={true}
                    getRowId={(row) => row.ICODE}
                    components={{
                      Header: () => {
                        const headerDetails = columns;
                        return (
                          <div
                            key={() => 1}
                            className="   flex rounded-sm rounded-b-none text-white"
                          >
                            {headerDetails?.map((item, index) => {
                              const backgroundColor =
                                item.field !== 'SQNTY' &&
                                item.field !== 'SAMOUNT'
                                  ? '#383535'
                                  : '#464445';

                              return (
                                <div
                                  key={index}
                                  className="  flex justify-center items-center py-3"
                                  style={
                                    item.flex
                                      ? index < headerDetails.length - 1
                                        ? {
                                            flex: item.flex,
                                            borderRight: '1px solid #4a4948',
                                            backgroundColor: backgroundColor,
                                          }
                                        : {
                                            flex: item.flex,
                                            backgroundColor: backgroundColor,
                                          }
                                      : index < headerDetails.length - 1
                                      ? {
                                          width: item.width,
                                          backgroundColor: backgroundColor,
                                          borderRight: '1px solid white',
                                        }
                                      : {
                                          width: item.width,
                                          backgroundColor: backgroundColor,
                                        }
                                  }
                                >
                                  {item.renderHeader()}
                                </div>
                              );
                            })}
                          </div>
                        );
                      },
                      NoRowsOverlay: () => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              width: '100%',
                            }}
                          >
                            No Records Found
                          </div>
                        );
                      },
                    }}
                    sx={
                      window.innerWidth < 770
                        ? {
                            backgroundColor: 'white',

                            fontSize: 10,
                            '& .super-app-theme--header': {
                              backgroundColor: '#f5f0e8',
                              display: 'none',
                            },
                            '& .super-app-theme--TQNTY': {
                              backgroundColor: '#f4f4f4',
                              color: '#f0f0f0',
                            },
                            borderRadius: 2,
                          }
                        : {
                            backgroundColor: 'white',
                            fontSize: 13,
                            borderRadius: 2,
                            '& .super-app-theme--header': {
                              backgroundColor: '#f5f0e8',
                              borderRight: `1px solid #4a4948`,

                              display: 'none',
                            },
                            '& .super-app-theme--IQNTY': {
                              backgroundColor: '#f4f4f4',
                              color: '#122907',
                            },
                            '& .super-app-theme--PRV_BAL': {
                              color: 'red',
                            },
                            '& .super-app-theme--RQNTY': {
                              backgroundColor: '#f4f4f4',
                              color: '#122907',
                            },
                            '& .super-app-theme--TQNTY': {
                              backgroundColor: '#f4f4f4',
                              color: '#f0f0f0',
                            },
                          }
                    }
                    columns={columns}
                    onCellDoubleClick={(p) => {
                      if (
                        p.field === 'IQNTY' ||
                        p.field === 'RQNTY' ||
                        (p.field === 'SALE_PRICE' && userType === 'MANAGER')
                      ) {
                        setEditType(p.field);
                        dispatch(setnumericKeyboardType(p.field));
                        dispatch(setnumericKeyboardStatus(true));
                        if (p.field === 'IQNTY') {
                          dispatch(
                            setIssueStockItem({
                              title: p.row.ITEMNAME,
                              id: p.row.ICODE,
                              value: p.row.IQNTY,
                            })
                          );
                        }
                        if (p.field === 'RQNTY') {
                          dispatch(
                            setIssueStockItem({
                              title: p.row.ITEMNAME,
                              id: p.row.ICODE,
                              value: p.row.RQNTY,
                            })
                          );
                        }
                        if (p.field === 'SALE_PRICE') {
                          dispatch(
                            setIssueStockItem({
                              title: p.row.ITEMNAME,
                              id: p.row.ICODE,
                              value: p.row.SALE_PRICE,
                            })
                          );
                        }
                      }
                    }}
                    scrollbarSize={0}
                    rows={rows}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    disableColumnSelector
                    disableColumnReorder
                    onCellEditCommit={(e) => {
                      edit(e);
                    }}
                  />
                </div>
                <div className="">
                  <TotalIssueStockFooter
                    columns={columns}
                    fromDate={date}
                    startKm={startKm}
                    endKM={endKM}
                    getStationBalance={getStationBalance}
                    stationId={station_id}
                    stationLess={stationLess}
                    prevBalance={prevBalance}
                    balance={balance}
                    setBalance={setBalance}
                    setDebitStatus={setDebitStatus}
                    setDailyExpenseStatus={setDailyExpenseStatus}
                    handlePrint={handlePrint}
                    setExpenseStatus={setExpenseStatus}
                    rows={rows}
                    cash={cash}
                    setCash={setCash}
                    expense={expense}
                    bracketLess={bracketLess}
                    setExpense={setExpense}
                    less={less}
                    setLess={setLess}
                    collection={collection}
                    setCollection={setCollection}
                    due={due}
                    setlessStatus={setlessStatus}
                    setDue={setDue}
                    debit={debit}
                    setDebit={setDebit}
                    onSaveIssueStock={onSaveIssueStock}
                    handleUndo={handleUndo}
                    setTotalDistance={setTotalDistance}
                    totalDistance={totalDistance}
                    setvehicalAvg={setvehicalAvg}
                    deiselRate={dieselRate ? dieselRate : 0}
                    deiselAmt={deiselAmt}
                    setDeiselAmt={setDeiselAmt}
                    vehicalAvg={vehicalAvg}
                    deiselQnty={deiselQnty}
                  />
                </div>
              </div>
            </div>

            <DrawerFilters
              status={isDrawerOpen}
              selectedOption={selectedOption}
              setStatus={setIsDrawerOpen}
              data={
                <div
                  style={{
                    width: 450,
                  }}
                  className="  flex flex-col h-full "
                >
                  <div className="flex justify-between rounded-t-sm bg-slate-100">
                    <div className="flex items-center pl-3 text-sm font-semibold">
                      Item Master - Help
                    </div>
                    <button
                      onClick={() => {
                        // if (selectedOption === 'option2') {
                        setIsDrawerOpen(false);
                        // }
                      }}
                      className="w-fit p-1 "
                    >
                      <Close
                        sx={{
                          color: 'red',
                        }}
                      />
                    </button>
                  </div>
                  <div
                    style={
                      window.innerWidth <= 1024
                        ? {
                            display: 'block',
                            border: '1px solid #b5b3b3',

                            width: '100%',
                          }
                        : window.innerWidth <= 1280
                        ? {
                            display: 'block',
                            border: '1px solid #b5b3b3',

                            width: '100%',
                          }
                        : window.innerWidth <= 1600
                        ? {
                            display: 'block',
                            border: '1px solid #b5b3b3',

                            width: '100%',
                          }
                        : {
                            display: 'block',
                            border: '1px solid #b5b3b3',

                            width: '100%',
                          }
                    }
                    className="flex w-full bg-white rounded-b-sm h-[90px]  pl-3 pr-3 "
                  >
                    {isloading ? (
                      <div className="flex">
                        {[
                          '1',
                          '2',
                          '3',
                          '4',
                          '5',
                          '6',
                          '7',
                          '8',
                          '9',
                          '10',
                          '11',
                          '12',
                          '13',
                          '14',
                          '15',
                          '16',
                        ].map((i, key) => {
                          return (
                            <Skeleton
                              key={key}
                              variant="rectangular"
                              animation="wave"
                              height={70}
                              sx={{
                                fontSize: '1rem',
                                borderRadius: '7px',
                                marginRight: '6px',
                                paddingX: '12px',
                              }}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                        }}
                        className="overflow-x-scroll  rounded-sm"
                      >
                        <ul className="flex items-center h-full ">
                          {data
                            ? data.map((item, key) => (
                                <div
                                  key={key}
                                  className="flex"
                                  style={{
                                    // width: '80%',
                                    border:
                                      item.SECTION_NAME === sName
                                        ? '1px solid #C2B94F'
                                        : '0px solid #C2B94F',
                                    height: '45px',
                                    borderRadius: '7px',
                                    marginRight: '8px',
                                    textTransform: 'none',
                                    backgroundColor: '#f5eb82',
                                    fontSize: '15px',
                                    color: '#4a4a4a',
                                  }}
                                >
                                  <button
                                    key={key}
                                    onClick={() => {
                                      if (station_id) {
                                        setSName(item.SECTION_NAME);
                                        GetItemsList(
                                          item.SECTION_NAME,
                                          dispatch,
                                          prefix,
                                          setItems,
                                          setIsLoading,
                                          setAllData,
                                          token
                                        );
                                      } else {
                                        dispatch(
                                          setAlertWithTitle({
                                            title: 'Alert',
                                            msg: 'Please select a station first',
                                          })
                                        );
                                      }
                                    }}
                                    className="text-[#4a4a4a] px-4 text-[15px]  font-semibold whitespace-nowrap "
                                  >
                                    {toProperCase(item.SECTION_NAME)}
                                  </button>
                                  {item.SECTION_NAME === sName && (
                                    <button
                                      style={{
                                        paddingRight: '5px',
                                      }}
                                      onClick={() => {
                                        setSName('');
                                      }}
                                    >
                                      <Close
                                        fontSize={'small'}
                                        sx={{
                                          fontSize: '12px',
                                        }}
                                      />
                                    </button>
                                  )}
                                </div>
                              ))
                            : [
                                '1',
                                '2',
                                '3',
                                '4',
                                '5',
                                '6',
                                '7',
                                '8',
                                '9',
                                '10',
                                '11',
                                '12',
                                '13',
                                '14',
                                '15',
                                '16',
                              ].map(() => {
                                return (
                                  <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    height={70}
                                    sx={{
                                      fontSize: '1rem',
                                      borderRadius: '7px',
                                    }}
                                  />
                                );
                              })}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div
                    style={
                      window.innerWidth < 500
                        ? { visibility: 'hidden', display: 'none' }
                        : window.innerWidth <= 768
                        ? {
                            border: '1px solid #b5b3b3',
                            color: '#4a4a4a',
                          }
                        : window.innerWidth <= 1024
                        ? {
                            border: '1px solid #b5b3b3',
                            color: '#4a4a4a',
                          }
                        : window.innerWidth <= 1280
                        ? {
                            border: '1px solid #b5b3b3',
                            color: '#4a4a4a',
                          }
                        : window.innerWidth <= 1600
                        ? {
                            border: '1px solid #b5b3b3',
                            color: '#4a4a4a',
                          }
                        : {
                            border: '1px solid #b5b3b3',
                            color: '#4a4a4a',
                          }
                    }
                    className="w-full h-[7%] bg-white  flex px-3 mt-2 rounded-sm overflow-hidden   "
                  >
                    <input
                      value={searchItem}
                      onChange={(e) => {
                        setSearchItem(e.target.value.toLowerCase());
                      }}
                      type="search"
                      className="w-full  text-sm h-full py-2  focus:outline-none text-[#4a4a4a]"
                      placeholder="Search Item"
                    />
                  </div>

                  <div
                    style={
                      window.innerWidth < 500
                        ? { visibility: 'hidden', display: 'none' }
                        : window.innerWidth <= 768
                        ? {
                            border: '1px solid #b5b3b3',
                            color: '#4a4a4a',
                          }
                        : window.innerWidth <= 1024
                        ? {
                            border: '1px solid #b5b3b3',
                            color: '#4a4a4a',
                          }
                        : window.innerWidth <= 1280
                        ? {
                            border: '1px solid #b5b3b3',
                            color: '#4a4a4a',
                          }
                        : window.innerWidth <= 1600
                        ? {
                            border: '1px solid #b5b3b3',
                            color: '#4a4a4a',
                          }
                        : {
                            border: '1px solid #b5b3b3',
                            color: '#4a4a4a',
                          }
                    }
                    className="p-3 bg-white mt-2 flex flex-col h-[81%] overflow-hidden  t:text-xs xl:text-base rounded-sm "
                  >
                    <div className="w-full  rounded-sm   overflow-hidden overflow-y-auto ">
                      {isloading ? (
                        <div className="grid gap-2 grid-cols-2 ll:grid-cols-3">
                          {[
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                            '10',
                            '11',
                            '12',
                            '13',
                            '14',
                            '15',
                            '16',
                          ].map((i, key) => {
                            return (
                              <Skeleton
                                key={key}
                                variant="rectangular"
                                animation="wave"
                                height={70}
                                sx={{ fontSize: '1rem', borderRadius: '7px' }}
                              />
                            );
                          })}
                        </div>
                      ) : (
                        <div className="grid gap-2 grid-cols-3 ll:grid-cols-3  ">
                          {items[0]
                            ? items.map((i, index) => (
                                <ItemBox
                                  key={index}
                                  item={i}
                                  index={index}
                                  itemNameStyle={itemNameStyle}
                                  onClick={() => {
                                    if (selectedOption === 'option2') {
                                      handleRowSelect(i);
                                    } else {
                                      dispatch(
                                        setAlertWithTitle({
                                          title: 'Error',
                                          msg: 'Please Select Issue!',
                                        })
                                      );
                                    }
                                  }}
                                />
                              ))
                            : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <ModListStationMaster
        onRowClick={(params) => {
          const code = params.row.CODE;
          const station = params.row.STATION_NAME;
          setStationInIssue(code, station);

          dispatch(setListStationMasterModalStatus(false));
        }}
      />

      {selectedPrintOption === 'print' && windowSize ? (
        <div ref={printRef} style={{ display: 'block', visibility: 'hidden' }}>
          <PrintIssueReport
            data={rows.filter((i) => {
              if (i.IQNTY && i.IQNTY > 0) {
                return true;
              }
              if (i.RQNTY && i.RQNTY > 0) {
                return true;
              }
              if (i.SQNTY && i.SQNTY > 0) {
                return true;
              }
              if (i.PRV_BAL && i.PRV_BAL > 0) {
                return true;
              }
              return false;
            })}
            columns={columns}
            stationId={station_id}
            stationName={stationName}
            date={date}
            mobile={mobile}
            truckNo={truckNo}
            footer={
              <div>
                <div className=" border text-sm border-black rounded-sm mt-3">
                  <div className="w-full flex">
                    <div
                      style={{
                        width: '240px',
                        borderRight: '1px solid #b5b3b3',
                      }}
                      className="flex"
                    >
                      <div
                        style={{
                          borderRight: '1px solid #b5b3b3',
                        }}
                        className="w-1/2 py-2  px-2 "
                      >
                        {' '}
                        Prev Bal.
                      </div>
                      <div className="flex  grow justify-end items-center text-right pr-1">
                        {FormatNumber2(prevBalance)}
                      </div>
                    </div>

                    <div
                      style={{
                        width: '240px',
                        borderRight: '1px solid #b5b3b3',
                      }}
                      className="flex"
                    >
                      <div
                        style={{
                          borderRight: '1px solid #b5b3b3',
                        }}
                        className="w-1/2 py-2  px-2 "
                      >
                        {' '}
                        Total Qnty
                      </div>
                      <div className="flex  grow justify-end items-center text-right pr-1">
                        {FormatNumber2(
                          rows.reduce(
                            (acc, row) => acc + Number(row.IQNTY + row.PRV_BAL),
                            0
                          )
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        width: '240px',
                        borderRight: '1px solid #b5b3b3',
                      }}
                      className="flex"
                    >
                      <div
                        style={{
                          borderRight: '1px solid #b5b3b3',
                        }}
                        className="w-1/2 py-2  px-2 "
                      >
                        {' '}
                        Issue Amt.
                      </div>
                      <div className="flex  grow justify-end items-center text-right pr-1">
                        {FormatNumber2(
                          rows.reduce(
                            (total, row) => Number(total) + Number(row.IAMOUNT),
                            0
                          )
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        width: '240px',
                      }}
                      className="flex"
                    >
                      <div
                        style={{
                          borderRight: '1px solid #b5b3b3',
                        }}
                        className="w-1/2 py-2  px-2 "
                      >
                        {' '}
                        Balance
                      </div>
                      <div className="flex  grow justify-end items-center text-right pr-1">
                        {FormatNumber2(balance)}
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex border-t ">
                    <div
                      style={{
                        width: '240px',
                        borderRight: '1px solid #b5b3b3',
                      }}
                      className="flex"
                    >
                      <div
                        style={{
                          borderRight: '1px solid #b5b3b3',
                        }}
                        className="w-1/2 py-2  px-2 "
                      >
                        {' '}
                        Cash
                      </div>
                      <div className="flex  grow justify-end items-center text-right pr-1">
                        {FormatNumber2(cash)}
                      </div>
                    </div>

                    <div
                      style={{
                        width: '240px',
                        borderRight: '1px solid #b5b3b3',
                      }}
                      className="flex"
                    >
                      <div
                        style={{
                          borderRight: '1px solid #b5b3b3',
                        }}
                        className="w-1/2 py-2  px-2 "
                      >
                        {' '}
                        Expense
                      </div>
                      <div className="flex  grow justify-end items-center text-right pr-1">
                        {FormatNumber2(expense)}
                      </div>
                    </div>

                    <div
                      style={{
                        width: '240px',
                        borderRight: '1px solid #b5b3b3',
                      }}
                      className="flex"
                    >
                      <div
                        style={{
                          borderRight: '1px solid #b5b3b3',
                        }}
                        className="w-1/2 py-2  px-2 "
                      >
                        {' '}
                        Debit
                      </div>
                      <div className="flex  grow justify-end items-center text-right pr-1">
                        {FormatNumber2(debit)}
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex border-t ">
                    <div
                      style={{
                        width: '240px',
                        borderRight: '1px solid #b5b3b3',
                      }}
                      className="flex"
                    >
                      <div
                        style={{
                          borderRight: '1px solid #b5b3b3',
                        }}
                        className="w-1/2 py-2  px-2 "
                      >
                        {' '}
                        Less
                      </div>
                      <div className="flex  grow justify-end items-center text-right pr-1">
                        {FormatNumber2(less)}
                      </div>
                    </div>

                    <div
                      style={{
                        width: '240px',
                        borderRight: '1px solid #b5b3b3',
                      }}
                      className="flex"
                    >
                      <div
                        style={{
                          borderRight: '1px solid #b5b3b3',
                        }}
                        className="w-1/2 py-2  px-2 "
                      >
                        {' '}
                        Collection
                      </div>
                      <div className="flex  grow justify-end items-center text-right pr-1">
                        {FormatNumber2(collection)}
                      </div>
                    </div>

                    <div
                      style={{
                        width: '240px',
                        borderRight: '1px solid #b5b3b3',
                      }}
                      className="flex"
                    >
                      <div
                        style={{
                          borderRight: '1px solid #b5b3b3',
                        }}
                        className="w-1/2 py-2  px-2 "
                      >
                        {' '}
                        Due
                      </div>
                      <div className="flex  grow justify-end items-center text-right pr-1">
                        {FormatNumber2(due)}
                      </div>
                    </div>
                  </div>
                </div>
                <IssueDayEndCalculation
                  collection={collection}
                  debit={debit}
                  hideBillingTotal={true}
                  totalDistance={totalDistance}
                  vehicalAvg={vehicalAvg}
                  deiselAmt={deiselAmt}
                  checked={true}
                  setChecked={() => {}}
                  stationId={station_id}
                />
              </div>
            }
            helperName={helperName}
          />
        </div>
      ) : (
        <div style={{ display: 'block', visibility: 'hidden' }}>
          <PrintIssueMobileReport
            ref={thermalPrintRef}
            data={rows.filter((i) => {
              if (i.IQNTY && i.IQNTY > 0) {
                return true;
              }
              if (i.RQNTY && i.RQNTY > 0) {
                return true;
              }
              if (i.SQNTY && i.SQNTY > 0) {
                return true;
              }
              return false;
            })}
            columns={columns}
            stationId={station_id}
            stationName={stationName}
            date={date}
            mobile={mobile}
            truckNo={truckNo}
            helperName={helperName}
          />
        </div>
      )}
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <StationWiseIssueModal />
      <ModExpenseColllection
        setCollection={setCollection}
        status={expenseStatus}
        setStatus={setExpenseStatus}
        stationId={station_id}
        stationName={stationName}
        date={date}
      />
      <ModPartyWiseDisount
        setLess={setBracketLess}
        status={lessStatus}
        setStatus={setlessStatus}
        stationId={station_id}
        stationName={stationName}
        date={date}
      />
      <ModDailyExpense
        stationId={station_id}
        stationName={stationName}
        status={dailyExpenseStatus}
        setExpense={setExpense}
        setStatus={setDailyExpenseStatus}
        date={date}
      />
      <ModDabit
        stationId={station_id}
        stationName={stationName}
        status={debitStatus}
        setDebit={setDebit}
        setStatus={setDebitStatus}
        date={date}
      />
      {numericType === 'IQNTY' && (
        <>
          <ModNumericKeyboard
            onChange={(e) => {
              edit(e);
            }}
            editType={editType}
            onSave={onSaveIssueStock}
            mode={'dark'}
          />
        </>
      )}
      {numericType === 'RQNTY' && (
        <>
          <ModNumericKeyboard
            onChange={(e) => {
              edit(e);
            }}
            editType={editType}
            onSave={onSaveIssueStock}
            mode={'dark'}
          />
        </>
      )}
      {numericType === 'SALE_PRICE' && (
        <>
          <ModNumericKeyboard
            onChange={(e) => {
              edit(e);
            }}
            editType={editType}
            onSave={onSaveIssueStock}
            mode={'dark'}
          />
        </>
      )}
      {numericType === 'START_KM' && (
        <>
          <ModNumericKeyboard
            onChange={(e) => {
              edit(e);
            }}
            editType={editType}
            onSave={onSaveIssueStock}
            mode={'dark'}
          />
        </>
      )}
      {numericType === 'END_KM' && (
        <>
          <ModNumericKeyboard
            onChange={(e) => {
              edit(e);
            }}
            editType={editType}
            onSave={onSaveIssueStock}
            mode={'dark'}
          />
        </>
      )}
      <ModEmployeeMasterHelp
        onRowClick={(p) => {
          setMobile(p.row.mobile);
          setTruckNo(p.row.vehicle_no);
          setHelperName(p.row.employee_name);
          dispatch(setModEmployeeMasterHelpStatus(false));
        }}
      />

      {/* <OfflineBills status={true} billDetails={[]} /> */}
    </div>
  );
};

export default IssueStock;
