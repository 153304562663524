import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsSquare, BsCheckSquare } from 'react-icons/bs';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useFocus } from '../utility/hooks/hooks';
import {
  selectISFirebaseSocketOn,
  selectWidth,
} from '../utility/Redux/profile';
import {
  setAlertMsg,
  setALertStatus,
  setAlertWithTitle,
  setCheckEmailAlert,
  setloading,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import AlertModal from '../utility/component/modals/alert';
import CheckEmailModall from '../utility/component/modals/resetPassword';
import EmailVerify from '../utility/component/modals/verifyEmailModal';
import Gicon from '../utility/images/go_logo.svg';
import EmailVerified from '../utility/component/modals/emailVerified';

import CryptoJS from 'crypto-js';
import { UserLogin } from '../utility/component/buttons';
import AlertModal2 from '../utility/component/modals/alertWithLottie';
import ForgetModel from './forgetModel';
import RoutbillingLogo from '../utility/component/styles/routbillingLogo';
import SpinnerLoading from '../utility/component/modals/spinnerLoading';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { emailRegex, mobileRegex } from '../utility/constant';
import { isSubdomain, moveHome, runLoginFunc } from './func';
import {
  selectExpireAt,
  selectLoginData,
  selectOtpModalStatus,
  selectRandomOtp,
  selectUserData,
  setOtpMobile,
  setOtpStatus,
} from '../utility/Redux/security';
import OtpAlertModal from '../utility/component/modals/otpAlertModal';
import { CallAPIPromise } from '../routeBilling/menu/dashboard/biiling/modal/comman';
const secretPass = process.env.REACT_APP_SECRET_KEY;

export const Login = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const isFirebaseSocket = useSelector(selectISFirebaseSocketOn);
  const userName = searchParams.get('userName');
  const searchpswrd = searchParams.get('password');
  const [remember, setRember] = useState(false);
  const currentUrl = window.location.href;
  const windowWiidt = useSelector(selectWidth);
  const [passRef, setPasswordFocus] = useFocus();
  const [hidden, sethidden] = useState(true);
  const [email, setEmailLogin] = useState(userName ? userName : '');
  const [password, setpassword] = useState(searchpswrd ? searchpswrd : '');
  const [forget, setForget] = useState(false);
  const [emailBordered, setEmailLoginBordered] = useState('gray');
  const [PasswordBordered, setPasswordBordered] = useState('gray');
  const move = useNavigate();
  const [subDomain, setSubDomain] = useState('');
  const otpStatus = useSelector(selectOtpModalStatus);
  const randomOTP = useSelector(selectRandomOtp);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const loginData = useSelector(selectLoginData);
  const userData = useSelector(selectUserData);
  const expireAt = useSelector(selectExpireAt);
  const location = useLocation();

  useEffect(() => {
    if (currentUrl.includes('shopkeeper')) {
      move('/auth/shopkeeper_login');
    }
  }, []);

  const initialValues = {
    Email: '',
    login_password: '',
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const isRemmberd = JSON.parse(localStorage.getItem('rememberd'));

    if (isRemmberd) {
      const bytes = CryptoJS.AES.decrypt(isRemmberd.email, secretPass);
      const bytes2 = CryptoJS.AES.decrypt(isRemmberd.password, secretPass);

      if (bytes.sigBytes < 0 || bytes2.sigBytes < 0) {
        localStorage.removeItem('rememberd');
      } else {
        const decEmail = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const decPass = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));

        setEmailLogin(decEmail);
        setpassword(decPass);
        setRember(true);
      }
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => sethidden(true), 1000);
    return () => clearTimeout(timer);
  }, [hidden]);

  useEffect(() => {
    localStorage.removeItem('stationName');
    localStorage.removeItem('stationId');
    localStorage.removeItem('billDetails');
    localStorage.removeItem('userName');
    localStorage.removeItem('count');
    localStorage.removeItem('tkn');
    localStorage.removeItem('company');
    localStorage.removeItem('histryId');
    localStorage.removeItem('prefix');
    // localStorage.removeItem('expiresAt');
    localStorage.removeItem('orderdItemList');
    localStorage.removeItem('userType');
    localStorage.removeItem('companyDetails');
  }, []);

  const loginApi = () => {
    dispatch(setSpinnerLoading('Checking username'));

    if (emailRegex.test(email) || mobileRegex.test(email)) {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      // const hmac = CryptoJS.HmacSHA256(message, secret);
      const hmac = CryptoJS.HmacSHA256(email, secretPass);
      // Convert the HMAC to a string and encode it using Base64
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      let userEmail = emailRegex.test(email) ? email : '';
      let userMobile = mobileRegex.test(email) ? email : '';

      if (password.length >= 5) {
        runLoginFunc(
          userEmail,
          userMobile,
          hmacString,
          isFirebaseSocket,
          dispatch,
          password,
          remember,
          move
        );
      } else {
        if (password) {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Incorrect password',
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Write password',
            })
          );
        }
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Invalid email or mobile',
        })
      );
    }
  };

  const forgetModel = (e) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[6-9]\d{9}$/;
    if (re.test(e)) {
      setForget(true);
    } else {
      dispatch(
        setAlertMsg('Enter valid user email address for change password')
      );
      dispatch(setALertStatus(true));
    }
  };

  const forgetPassword = (emailCheck) => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[6-9]\d{9}$/;
    if (re.test(emailCheck) || phoneRegex.test(emailCheck)) {
      dispatch(setloading(true));

      CallAPIPromise(
        '/api/users/findUserDetail',
        {
          user_email: !phoneRegex.test(emailCheck) ? emailCheck : '',
          user_mobile: phoneRegex.test(emailCheck) ? emailCheck : '',
        },
        hmacString,
        dispatch
      )
        .then((res) => {
          if (res.data[0]) {
            CallAPIPromise(
              `/api/users/resetUserPasswordEmail`,
              {
                user_email: !phoneRegex.test(emailCheck) ? emailCheck : '',
                user_name: res.data[0].user_name,
                user_mobile: phoneRegex.test(emailCheck) ? emailCheck : '',
              },
              hmacString,
              dispatch
            )
              .then((r) => {
                dispatch(setCheckEmailAlert(true));
                // move('/auth/checkEmail')
              })
              .catch((err) => {
                dispatch(setAlertMsg(err.code + ' ' + err.message));
                dispatch(setALertStatus(true));
              })
              .finally(() => {
                dispatch(setloading(false));
              });
          } else {
            dispatch(setAlertMsg('Not registered user'));
            dispatch(setALertStatus(true));
          }
        })
        .catch((e) => {
          dispatch(setAlertMsg('Something wrong'));
          dispatch(setALertStatus(true));
        });
    } else {
      dispatch(setAlertMsg('Invailed email address'));
      dispatch(setALertStatus(true));
    }
  };

  useEffect(() => {
    const currentURL = window.location.href;

    setSubDomain(isSubdomain(currentURL));
  }, []);
  useEffect(() => {
    if (indexedDB.databases) {
      indexedDB
        .databases()
        .then((databases) => {
          databases.forEach((dbInfo) => {
            if (dbInfo.name !== 'SaveIssueCart') {
              if (dbInfo.name !== 'Calculations') {
                indexedDB.deleteDatabase(dbInfo.name);
              }
            }
          });
        })
        .catch((error) => {
          console.error('Error listing databases:', error);
        });
    } else {
      // Fallback method for browsers that do not support indexedDB.databases()
      console.log('indexedDB.databases() is not supported in this browser.');
    }
  }, []);

  return (
    <div className="items-center flex w-screen h-screen justify-center bg-white">
      {!searchParams.get('userName') && !searchParams.get('password') ? (
        <div className="w-auto  bg-white  h-auto ">
          <div className="h-full w-full ">
            <div className="h-full w-full grid grid-cols-1 t:grid-cols-2 ">
              <div className="h-full w-full flex   ">
                <div className="h-full items-center justify-center t:justify-end t:mr-4 w-full flex  ">
                  <RoutbillingLogo />
                </div>
                <div className="w-4 hidden t:flex  justify-center  h-full items-center ">
                  <div
                    style={{
                      borderLeft: '1px solid #e5e7eb',
                    }}
                    className=" h-full "
                  >
                    {' '}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center t:items-start justify-start t:ml-4 ">
                <div className="mt-1 font-semibold  flex justify-start  text-[20px] h-fit items-center">
                  Login
                </div>

                <div className="mt-2  ">
                  <div>
                    <div className=" flex justify-between  items-center">
                      <div
                        style={{
                          width: windowWiidt >= 768 ? '300px' : '300px',
                        }}
                      >
                        <div
                          style={{
                            border: `0.5px solid ${emailBordered}`,
                            borderRadius: '3px',
                            overflow: 'hideen',
                          }}
                        >
                          <input
                            autoSave={initialValues.Email}
                            onFocus={() => setEmailLoginBordered('#211adb')}
                            onBlur={() => setEmailLoginBordered('gray')}
                            size={'small'}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                setPasswordFocus();
                              }
                            }}
                            value={email}
                            style={{
                              fontSize: '13px',
                              borderRadius: '3px',
                              overflow: 'hideen',
                            }}
                            // pattern="/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/"
                            placeholder="Email Address"
                            name="Email"
                            id="Email"
                            onChange={(t) => {
                              setEmailLogin(t.target.value.toLowerCase());
                            }}
                            className="outline-0 w-full py-2 pl-3 "
                          />
                        </div>
                      </div>
                    </div>

                    <div className=" justify-between  mt-4  items-center ">
                      <div className="flex items-center justify-between  ">
                        <div
                          style={{
                            width: windowWiidt >= 768 ? '300px' : '300px',
                          }}
                        >
                          <div
                            style={{
                              border: `0.5px solid ${PasswordBordered}`,
                              borderRadius: '3px',
                              overflow: 'hideen',
                            }}
                            className="flex  items-center w-full justify-between "
                          >
                            <input
                              size={'small'}
                              //  autoSave={remember}
                              onFocus={() => setPasswordBordered('#211adb')}
                              onBlur={() => {
                                setPasswordBordered('gray');
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  loginApi();
                                }
                              }}
                              ref={passRef}
                              className="outline-0 bg-white w-full pl-3"
                              type={'password'}
                              value={password}
                              name="login_password"
                              id="login_password"
                              placeholder="Password"
                              datatype={'password'}
                              // error={
                              //   touched.login_password ? errors.login_password : null
                              // }
                              style={{
                                fontSize: '13px',
                                borderRadius: '3px',
                                overflow: 'hideen',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                              }}
                              onChange={(p) => {
                                setpassword(p.target.value);
                              }}
                            />

                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" flex  items-center justify-between mt-3">
                      <div className="flex items-center">
                        <div>
                          <label htmlFor="checkbox">
                            {!remember && (
                              <BsSquare
                                fill={'grey'}
                                size={12}
                                onClick={() => {
                                  setRember(true);
                                }}
                              />
                            )}
                            {remember && (
                              <BsCheckSquare
                                fill={'#f5874f'}
                                size={12}
                                onClick={() => {
                                  setRember(false);
                                }}
                              />
                            )}
                          </label>
                        </div>{' '}
                        <div
                          style={{ fontSize: 12 }}
                          className=" text-slate-500 pl-1  flex whitespace-nowrap  items-center"
                        >
                          Remember me
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: '11px',
                        }}
                        className="w-full text-slate-500 flex items-center justify-end pr-1 "
                      ></div>
                    </div>

                    <div className="w-full pt-5 flex items-center justify-center ">
                      <UserLogin onClick={loginApi} />
                    </div>
                  </div>
                  <div className="flex mt-5 justify-between">
                    <div
                      style={{
                        fontSize: '13px',
                      }}
                      className="flex  justify-center "
                    >
                      New User ?{' '}
                      <button
                        onClick={() => {
                          move('/auth/signup');
                        }}
                      >
                        {' '}
                        <label className="pl-1  hover:cursor-pointer text-orange">
                          SignUp
                        </label>
                      </button>
                    </div>
                    <div className=" flex justify-end">
                      <button
                        onClick={() => {
                          if (email) {
                            forgetModel(email);
                          } else {
                            dispatch(
                              setAlertMsg(
                                'Please enter user email address for change password'
                              )
                            );
                            dispatch(setALertStatus(true));
                          }
                        }}
                      >
                        <div
                          style={{ fontSize: 13 }}
                          className="  flex justify-center whitespace-nowrap     text-slate-500"
                        >
                          Forgot
                          <label className="pl-1 font-medium  text-brown">
                            Password ?
                          </label>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              onClick={() => {
                window.open('https://geniusoffice.com', '_blank');
              }}
              className="mt-10 text-[13px] w-full justify-center items-center text-brown flex"
            >
              A product of{' '}
              <div className="pl-1 flex items-center">
                <img
                  alt=""
                  src={Gicon}
                  style={{
                    width: '14px',
                    height: '14px',
                  }}
                />
              </div>
              <label
                onClick={() => {
                  window.open('https://geniusoffice.com', '_blank');
                }}
                className="font-semibold hover:cursor-pointer pl-1 pr-1"
              >
                {' '}
                genius office
              </label>
              2023 All rights reserved.
            </button>
          </div>
        </div>
      ) : (
        <div>{subDomain}</div>
      )}
      <AlertModal2 />
      <AlertModal />
      <ForgetModel
        forget={forget}
        setForget={setForget}
        forgetPassword={forgetPassword}
        email={email}
      />
      <CheckEmailModall />

      <EmailVerify email={location.state} />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <EmailVerified />
      <SpinnerLoading />
      <OtpAlertModal
        status={otpStatus}
        otp={randomOTP}
        onCancel={() => {
          dispatch(setOtpStatus(false));
        }}
        onSubmit={() => {
          dispatch(setOtpStatus(false));

          moveHome(
            {
              data: {
                token: loginData.token,
                data: [
                  {
                    ...loginData,
                  },
                ],
              },
            },
            userData,
            expireAt,
            dispatch,
            remember,
            password,
            move
          );
        }}
      />
    </div>
  );
};
