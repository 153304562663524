import { Button, Radio } from '@mui/material';
import { setPrintStatus } from '../Redux/tableOrder';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
const ModifyButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#ebaf81',
        }}
      >
        <div className="text-black font-sans px-3">Modify </div>
      </Button>
    </>
  );
};

const SaveButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#a8cf45',
        }}
      >
        <div className="text-black px-4 font-sans">Save </div>
      </Button>
    </>
  );
};
const SaveNumericButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          width: '100%',
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#a8cf45',
        }}
      >
        <div className="text-black px-4 font-sans">Save </div>
      </Button>
    </>
  );
};
const OkNumericButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          width: '100%',
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#a8cf45',
        }}
      >
        <div className="text-black px-4 font-sans">Ok </div>
      </Button>
    </>
  );
};
const SaveVoucherbutton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          width: '80%',
          letterSpacing: '1px',
          backgroundColor: '#a8cf45',
        }}
      >
        <div className="text-black px-4 font-sans">Save </div>
      </Button>
    </>
  );
};

const AlertOkButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#1c1a1a',
          width: '100%',
          marginLeft: 4,
        }}
      >
        <div className="text-white   px-3 ">Ok </div>
      </Button>
    </>
  );
};

const OK_button = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#5cbe54',
        }}
      >
        <div className="text-white px-12 font-sans">Ok </div>
      </Button>
    </>
  );
};

const BillingSave = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#a8cf45',
          width:
            window.innerWidth <= 768 ? (window.innerWidth / 100) * 48 : '100%',
        }}
      >
        <div className="text-black px-8 font-sans">Save </div>
      </Button>
    </>
  );
};

const PrintButton = ({ onClick }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#bad4f9',
      }}
    >
      <div className="text-black px-4 font-sans ">Print </div>
    </Button>
  );
};

const SavePrintButton = ({ onClick }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#f5874f',
        marginRight: 3,
      }}
    >
      <div className="text-black px-4 font-sans "> Save & Print </div>
    </Button>
  );
};

const OkButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#a8cf45',
          marginLeft: 4,
        }}
      >
        <div className="text-black font-sans  px-3 ">Ok </div>
      </Button>
    </>
  );
};

const UndoButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#383535',
          marginLeft: 4,
        }}
      >
        <div className="text-white font-sans px-3  ">Undo </div>
      </Button>
    </>
  );
};
const DeleteButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#db3737',
          marginLeft: 4,
        }}
      >
        <div className="text-white font-sans px-3  ">Delete </div>
      </Button>
    </>
  );
};
const SignOut = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#FADB3F',
          marginLeft: 4,
        }}
      >
        <div className="text-black font-sans px-3  ">Sign Out </div>
      </Button>
    </>
  );
};
const UndoVouxherButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          width: '20%',
          backgroundColor: '#383535',
          marginLeft: 4,
        }}
      >
        <div className="text-white font-sans px-3  ">Undo </div>
      </Button>
    </>
  );
};

const Issue_Undo_button = ({ onClick }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#383535',

        padding: '0px',
        height: '100%',
        borderRadius: '0px',
        boxShadow: 'none',
      }}
    >
      <div className="text-white font-sans px-4 ">Undo </div>
    </Button>
  );
};
const Issue_button = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: 'rgb(168, 207, 69)',
          borderRadius: '0px',
          display: 'flex',
          alignItems: 'center',
          padding: '0px',
          height: '100%',
          boxShadow: 'none',
        }}
      >
        <div className="text-black px-10 ">Save </div>
      </Button>
    </>
  );
};
const IssuePrintButton = ({ onClick }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#bad4f9',
        padding: '0px',
        height: '100%',
        boxShadow: 'none',

        borderBottomRightRadius: '7px',
        borderBottomLeftRadius: '0px',
        borderTopRightRadius: '0px',

        borderTopLeftRadius: '0px',
      }}
    >
      <div className="text-black px-4 font-sans ">Print </div>
    </Button>
  );
};
const Alert1Button = ({ onClick, text }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#383535',
          width: '100%',
          marginLeft: 4,
        }}
      >
        <div className="text-white   px-3 ">{text} </div>
      </Button>
    </>
  );
};

const Alert2Button = ({ onClick, text }) => {
  return (
    <Button
      // ref={updateRef}
      onClick={onClick}
      variant="contained"
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#6b6969',
        width: '100%',
        marginLeft: 4,
      }}
    >
      <div className="text-white   px-3 ">{text}</div>
    </Button>
  );
};
const ExitButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#f1f5f9',
          marginLeft: 4,
        }}
      >
        <div className="text-black font-sans px-2 ">Exit </div>
      </Button>
    </>
  );
};
const Image_button = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          width: '100%',
          height: '100%',
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#88e0d0',
          color: '#4a4a4a',
        }}
      >
        <div className="  font-sans  flex justify-center items-center w-full whitespace-nowrap px-3 ">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            viewBox="0 0 24 32"

            // xml:space="preserve"
          >
            <path
              fill="black"
              d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M12,23C5.935,23,1,18.065,1,12
		S5.935,1,12,1s11,4.935,11,11S18.065,23,12,23z"
            />
            <path
              fill="black"
              d="M12,6.5c-3.032,0-5.5,2.467-5.5,5.5s2.468,5.5,5.5,5.5s5.5-2.467,5.5-5.5S15.032,6.5,12,6.5z M12,16.5
		c-2.481,0-4.5-2.019-4.5-4.5S9.519,7.5,12,7.5s4.5,2.019,4.5,4.5S14.481,16.5,12,16.5z"
            />
            <path
              fill="black"
              d="M11,9.455c-0.853,0-1.546,0.693-1.546,1.545c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5
		c0-0.301,0.245-0.545,0.546-0.545c0.276,0,0.5-0.224,0.5-0.5S11.276,9.455,11,9.455z"
            />
            <path
              fill="black"
              d="M22.804,31.237c0.254-0.43,0.261-0.948,0.02-1.385l-2.536-4.594c-0.133-0.242-0.439-0.33-0.679-0.196
		c-0.242,0.133-0.33,0.438-0.196,0.679l2.536,4.594c0.09,0.164,0.047,0.304-0.006,0.393C21.865,30.86,21.687,31,21.418,31H2.582
		c-0.269,0-0.447-0.14-0.524-0.271C2.005,30.64,1.962,30.5,2.052,30.335l2.536-4.594c0.134-0.242,0.046-0.546-0.196-0.679
		c-0.24-0.134-0.546-0.046-0.679,0.196l-2.536,4.594c-0.241,0.437-0.234,0.955,0.02,1.385C1.474,31.708,2.005,32,2.582,32h18.836
		C21.995,32,22.526,31.708,22.804,31.237z"
            />
          </svg>
          <div className="flex  items-center pl-1">Image </div>
        </div>
      </Button>
    </>
  );
};

const BackButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        sx={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#fec24a',
          border: '1px dotted gray',
          marginLeft: '3px',
        }}
      >
        <div className="text-black hover:text-white font-sans  flex justify-center w-full whitespace-nowrap px-2 ">
          <i
            style={{
              fontSize: '14px',
            }}
            className="bi bi-reply"
          ></i>
          <div className="flex  items-center pl-1">Back </div>
        </div>
      </Button>
    </>
  );
};
const ClearButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        sx={{
          width: '50%',
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#FEFEFE',
          border: '1px dotted gray',
          marginLeft: '12px',
        }}
      >
        <div className="text-red-500 hover:text-white font-sans  flex justify-center w-full whitespace-nowrap px-2 ">
          <div className="flex  items-center pl-1">Clear</div>
        </div>
      </Button>
    </>
  );
};
const BillingBackButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        sx={{
          textTransform: 'none',

          backgroundColor: '#fec24a',

          width:
            window.innerWidth <= 768 ? (window.innerWidth / 100) * 48 : '100%',
        }}
      >
        <div className="text-black font-sans w-full hover:text-white  flex justify-center whitespace-nowrap px-2 ">
          <i
            style={{
              fontSize: '14px',
            }}
            className="bi bi-reply"
          ></i>
          <div className="flex  items-center pl-1">Back </div>
        </div>
      </Button>
    </>
  );
};

const BackToLogin = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#fec24a',
          width: '100%',
          marginTop: '8px',
          height: '32px',
        }}
      >
        <div
          style={{
            fontSize: '13px',
          }}
          className="text-black font-sans  whitespace-nowrap "
        >
          <ReplyOutlinedIcon
            sx={{
              fontSize: '22px',
            }}
          />
          Back{' '}
        </div>
      </Button>
    </>
  );
};

const RegisterNewUser = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#fec24a',
          width: '100%',

          height: '36px',
        }}
      >
        <div
          style={{
            fontSize: '14px',
          }}
          className="text-black font-sans  whitespace-nowrap "
        >
          SignUp
        </div>
      </Button>
    </>
  );
};

const UserLogin = ({ onClick, disabled }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        disabled={disabled}
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#383535',
          width: '100%',

          height: '36px',
        }}
      >
        <div
          style={{
            fontSize: '14px',
          }}
          className="text-white font-sans  whitespace-nowrap "
        >
          Login
        </div>
      </Button>
    </>
  );
};

const GetOtpButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}

        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#383535',
        }}
      >
        <div
          style={{
            fontSize: '13px',
          }}
          className="text-white font-sans  whitespace-nowrap px-16"
        >
          Send OTP
        </div>
      </Button>
    </>
  );
};

const SubmitButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#383535',

          width: '100%',
        }}
      >
        <div
          style={{
            fontSize: '13px',
          }}
          className="text-white font-sans  whitespace-nowrap w-full"
        >
          Submit
        </div>
      </Button>
    </>
  );
};
const SendButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#383535',

          width: '100%',
        }}
      >
        <div
          style={{
            fontSize: '13px',
          }}
          className="text-white font-sans  whitespace-nowrap w-full"
        >
          Send
        </div>
      </Button>
    </>
  );
};

const SendGreenButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#a8cf45',
        }}
      >
        <div
          style={{
            fontSize: '13px',
          }}
          className="text-black font-sans px-5  whitespace-nowrap w-full"
        >
          Send
        </div>
      </Button>
    </>
  );
};

const CancelButton = ({ onClick }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: '#737371',

          width: '100%',
        }}
      >
        <div
          style={{
            fontSize: '13px',
          }}
          className="text-white font-sans  whitespace-nowrap w-full"
        >
          Cancel
        </div>
      </Button>
    </>
  );
};

const GeniusOfficeButton = ({ onClick, title, color, checked }) => {
  return (
    <>
      <Button
        // ref={updateRef}
        onClick={onClick}
        variant="contained"
        sx={{
          boxShadow: 'none',
        }}
        style={{
          textTransform: 'none',
          letterSpacing: '1px',
          backgroundColor: color ? color : '#f1f5f9',
          marginLeft: 4,
          padding: 0,
          border: '1px solid #b5b3b3',
        }}
      >
        <div
          style={{
            fontSize: 13,
          }}
          className="text-black font-sans  whitespace-nowrap flex items-center   pr-3 "
        >
          <Radio
            checked={checked}
            color="success"
            size="small"
            sx={{ backgroundColor: 'white', color: 'white' }}
          />
          {title}
        </div>
      </Button>
    </>
  );
};

export {
  ModifyButton,
  PrintButton,
  SaveButton,
  AlertOkButton,
  IssuePrintButton,
  UndoButton,
  Alert1Button,
  SaveVoucherbutton,
  Alert2Button,
  OkButton,
  ExitButton,
  ClearButton,
  OkNumericButton,
  Issue_Undo_button,
  GetOtpButton,
  BackButton,
  Image_button,
  SubmitButton,
  CancelButton,
  OK_button,
  BackToLogin,
  GeniusOfficeButton,
  SendButton,
  SavePrintButton,
  RegisterNewUser,
  UndoVouxherButton,
  UserLogin,
  Issue_button,
  SaveNumericButton,
  SendGreenButton,
  BillingSave,
  BillingBackButton,
  DeleteButton,
  SignOut,
};
