import { useDispatch, useSelector } from 'react-redux';
import { setSessionExpired, setSpinnerLoading } from '../utility/Redux/modal';
import { useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import { useEffect } from 'react';
import { selectCompanyAddress } from '../utility/Redux/profile';
import MasterHelp from '../utility/component/modals/masterHelp';
import { useNavigate } from 'react-router-dom';
import { CallAPIPromise } from '../routeBilling/menu/dashboard/biiling/modal/comman';
const TemplateMasterHelp = ({ onRowClick, status, onClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);

  const columns = [
    {
      field: 'template_for',
      renderHeader: (params) => <div>Template For</div>,
      width: 290,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'template_id',
      renderHeader: (params) => <div>Template ID</div>,
      width: 250,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'template_text',
      renderHeader: (params) => <div>Msg</div>,
      width: 200,
      headerAlign: 'center',
      align: 'left',
    },
  ];
  const companyDetail = useSelector(selectCompanyAddress);
  console.log(companyDetail);
  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.template_id.toString().includes(searchQuery);
        } else {
          return item.template_id
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading('Loading'));
    const data = {
      company_email: companyDetail.email,
      template_id: '',
    };
    CallAPIPromise(
      '/api/userSettings/messageTemplateList',
      data,
      token,
      dispatch
    )
      .then((p) => {
        if (!p.data.error) {
          let list = [];
          const data = p.data.response;

          for (let i = 0; i < data.length; i++) {
            list.push({
              ...data[i],
              id: i + 1,
            });
          }
          setFullList(list);
          setRows(list);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            onClose();
            setRows([]);
            setSearchQuery('');
          }}
          title={'Template | Help'}
          columns={columns}
          list={rows}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
          goToMaster={() => {
            navigate('/hsn_master');
          }}
        />
      </div>
    </>
  );
};

export default TemplateMasterHelp;
