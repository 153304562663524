import React, { useEffect, useRef, useState } from 'react';
import ModalHeaderStyle from '../../utility/component/styles/modalHeader';
import { json, useNavigate } from 'react-router-dom';
import GeniustextInput from '../../utility/component/mastertext';
import GeniusDatagrid from '../../utility/component/datagrid/geniusDatagrid';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCompanyAddress,
  selectPrefix,
  selectWidth,
} from '../../utility/Redux/profile';
import {
  SendButton,
  SendGreenButton,
  UndoButton,
} from '../../utility/component/buttons';
import ProfilePicFrame from '../../utility/component/styles/profilePicFrame';
import UploadImageButtons from '../../utility/component/UploadImageButtons';
import CallAPI from '../../utility/functions/getData';
import { selectToken } from '../../utility/Redux/security';
import moment from 'moment';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../utility/Redux/modal';
import { DownloadFile, UploadFile } from '../../utility/functions/firebase';
import axios from 'axios';
import { Chip, Divider } from '@mui/material';
import { CallAPIPromise } from './dashboard/biiling/modal/comman';

const SendPromoMsg = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const windowWidth = useSelector(selectWidth);
  const [selectedFile, setSelectedFile] = useState('');
  const webcamRef = useRef(null);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [webcamStatus, setWebcamStatus] = useState(false);
  const [capturedImage, setCapturedImage] = useState('');
  const [firebsaePic, setFirebasePic] = useState('');
  const [sendMessageData, setSendMsgData] = useState([]);
  const [msgListDateFocus, setMsgListDateFocus] = useState(false);
  const [sendMessageList, setSendMesasagelist] = useState([]);
  const [messagetype, setMesageType] = useState('WhatsApp');

  const [messagesListDate, setMsgListDate] = useState(
    moment().format('YYYY-MM-DD')
  );
  const products = [
    {
      name: 'genius office',
      value: 'genius office',
      from: 'MG3315c8fd955484a58d2e7e000c9b685b',
      from_whatsappNo_admin: '+12369001339',
    },

    {
      name: 'routeBilling',
      from: 'MGce45881cc2286f19e3e712a5bcacb141',
      value: 'routeBilling',
      from_whatsappNo_admin: '+13659771388',
    },
    {
      name: 'Tailoringx',
      from: 'MG33710709a90ac2cb41f7ed79e7b717d8',
      value: 'Tailoringx',
      from_whatsappNo_admin: '+12187789665',
    },
  ];
  const [productName, setproductName] = useState(products[0].value);

  const [templateDetails, setTamplateDetails] = useState([]);
  const fromList = products.filter((e) => e.value === productName);
  const from = fromList[0].from;
  const [templatelist, setTemplatelist] = useState([]);
  const [tabScreen, setTabScreen] = useState(1);
  const [templateID, setTemplateID] = useState('');
  const [templateVariablesValues, setTemplateVariablesValues] = useState([]);

  const companyDetails = useSelector(selectCompanyAddress);
  const [selectedSendMessageList, setSelectedSendMessageList] = useState([]);
  const [path, setPath] = useState('');
  const columns = [
    {
      field: 'CODE',
      width: windowWidth <= 1000 ? 60 : 80,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Code'}</strong>
      ),
    },
    {
      field: 'NAME',
      width: windowWidth <= 500 ? 100 : windowWidth <= 1000 ? 150 : 250,
      align: 'left',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Customer Name'}</strong>
      ),
    },
    {
      field: 'COMPANY_NAME',
      width: windowWidth <= 500 ? 100 : windowWidth <= 1000 ? 150 : 250,
      align: 'left',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Company Name'}</strong>
      ),
    },
    {
      field: 'MSG_MOBILE',
      width: windowWidth <= 500 ? 100 : windowWidth <= 1000 ? 150 : 250,
      align: 'left',
      type: 'text',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Mobile No.'}</strong>
      ),
    },

    {
      field: 'Select',
      width: windowWidth <= 1000 ? 70 : 75,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={selectedSendMessageList.some(
              (row) => row.CODE === params.row.CODE
            )}
            // isSelected={checkk}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        );
      },
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }} className="flex items-center">
          {'Select'}{' '}
          <input
            className="ml-2"
            type="checkbox"
            checked={selectedSendMessageList.length === customerDetails.length}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedSendMessageList(
                  customerDetails.map((e, i) => {
                    return {
                      DATE: moment().format('YYYY-MM-DD'),
                      CODE: e.CODE,
                      NAME: e.NAME,
                      COMPANY_NAME: e.COMPANY_NAME,
                      MSG_MOBILE: e.MSG_MOBILE,
                      PRODUCT_NAME: productName,
                      TEMPLATE_ID: templateID,
                      MESSAGE: templateDetails[0]
                        ? templateDetails[0]?.template_text
                        : '',
                      IMAGE_ID: '',
                      MESSAGE_TYPE: messagetype,
                    };
                  })
                );
              } else {
                setSelectedSendMessageList([]);
              }
            }}
          />
        </strong>
      ),
    },
  ];
  console.log(templateVariablesValues);

  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      setSelectedSendMessageList((p) => {
        return [
          ...p,
          {
            DATE: moment().format('YYYY-MM-DD'),
            CODE: row.CODE,
            NAME: row.NAME,
            COMPANY_NAME: row.COMPANY_NAME,
            MSG_MOBILE: row.MSG_MOBILE,
            PRODUCT_NAME: productName,
            TEMPLATE_ID: templateID,
            MESSAGE: templateDetails[0]
              ? templateDetails[0]?.template_text
              : '',
            IMAGE_ID: '',
            MESSAGE_TYPE: messagetype,
          },
        ];
      });
    } else {
      setSelectedSendMessageList((p) => p.filter((sf) => sf.CODE !== row.CODE));
    }
  };

  const columns2 = [
    {
      field: 'CODE',
      width: windowWidth <= 1000 ? 60 : 80,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Code'}</strong>
      ),
    },
    {
      field: 'NAME',
      width: windowWidth <= 500 ? 100 : windowWidth <= 1000 ? 150 : 250,
      align: 'left',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Customer Name'}</strong>
      ),
    },
    {
      field: 'COMPANY_NAME',
      width: windowWidth <= 500 ? 100 : windowWidth <= 1000 ? 150 : 250,
      align: 'left',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Company Name'}</strong>
      ),
    },
    {
      field: 'MSG_MOBILE',
      width: windowWidth <= 500 ? 100 : windowWidth <= 1000 ? 150 : 250,
      align: 'left',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Mobile No.'}</strong>
      ),
    },
  ];
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const loadCustomerDetails = () => {
    const data = {
      PRODUCT_NAME: productName,
    };
    CallAPI(
      '/api/PromoMessageMaster/loadCustomerMaster',
      data,
      token,
      (res) => {
        if (!res.data.error) {
          const data = res.data.response;
          const list = [];
          const selctedlist = [];
          for (let index = 0; index < data.length; index++) {
            list.push({
              ...data[index],
              id: index,
            });
            selctedlist.push({
              DATE: moment().format('YYYY-MM-DD'),
              CODE: data[index].CODE,
              NAME: data[index].NAME,
              COMPANY_NAME: data[index].COMPANY_NAME,
              MSG_MOBILE: data[index].MSG_MOBILE,
              PRODUCT_NAME: productName,
              TEMPLATE_ID: templateID,
              MESSAGE: templateDetails[0]
                ? templateDetails[0]?.template_text
                : '',
              IMAGE_ID: '',
              MESSAGE_TYPE: messagetype,
            });
          }
          setCustomerDetails(data);
          setSelectedSendMessageList(selctedlist);
        }
      },
      dispatch
    );
  };

  const listPromoMsg = () => {
    const data = {
      DATE: moment(messagesListDate).format('YYYY-MM-DD'),
    };
    dispatch(setSpinnerLoading('Getting list'));
    CallAPI(
      '/api/PromoMessageMaster/listPromoMessage',
      data,
      token,
      (res) => {
        if (!res.data.error) {
          const data = res.data.response;
          const list = [];
          for (let index = 0; index < data.length; index++) {
            list.push({
              ...data[index],
              id: index,
            });
          }
          setSendMesasagelist(list);
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };

  const sendWhatsappOldApi = (d, i, list) => {
    CallAPIPromise('/api/sendPromoMsg/sendWhatsApp', d, token, dispatch)
      .then((response) => {
        if (i === list.length - 1) {
          dispatch(
            setAlertWithTitle({
              title: 'Success',
              msg: 'Whatsapp is sent succesfully',
              lottie: 'success',
            })
          );
          setSelectedFile('');
          setCapturedImage('');
          setSelectedSendMessageList([]);
          listPromoMsg();
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Faild to send message',
            lottie: 'reject',
          })
        );
        if (err?.response?.data?.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Whastapp not sent',
            })
          );
        }
      });
  };
  const prefix = useSelector(selectPrefix);
  console.log(templateVariablesValues);
  const save = (s, variables) => {
    const isEmptyrequiredFilds = templateVariablesValues.filter(
      (t) => t.value === ''
    );
    if (!isEmptyrequiredFilds[0]) {
      const list = selectedSendMessageList.map((m) => {
        return {
          ...m,
          IMAGE_ID: s[1],
          TEMPLATE_ID: templateID,
          MESSAGE: templateDetails[0] ? templateDetails[0]?.template_text : '',
          messagetype: messagetype,
          PRODUCT_NAME: productName,
          MSG_FROM: from,
        };
      });
      console.log(list);

      const data = {
        message_cart: list,
      };
      dispatch(setSpinnerLoading('Sending message'));
      CallAPI(
        '/api/PromoMessageMaster/SavePromoMessage',
        data,
        token,
        (e) => {
          if (e.status === 200) {
            list.map((m, i) => {
              const d = {
                dataUrl: s[1],
                mobile: m.MSG_MOBILE,
                from: from,
                contentSid: templateID,
              };
              CallAPIPromise('/api/whatsApp/send-whatsapp-message', {
                type: 'admin',
                toPhoneNumber: m.MSG_MOBILE,
                INVNO: '0',
                AMOUNT: '0',
                prefix: prefix,
                template_for: templateDetails[0].template_for,
                companyEmail: companyDetails.email,
                message_for: templateDetails[0].template_for,
                rejected_reason: '',
                company_name: companyDetails.companyName,
                template_id_admin: templateID,
                from_whatsappNo_admin: fromList[0].from_whatsappNo_admin,
                template_variable_admin: variables
                  ? JSON.stringify(variables)
                  : templateVariablesValues[0]
                  ? JSON.stringify(
                      templateVariablesValues.reduce((acc, item, index) => {
                        acc[index + 1] = item.value; // Use (index + 1) as the key and item.value as the value
                        return acc;
                      }, {})
                    )
                  : '{}',
              }).then((e) => {});
              // sendWhatsappOldApi(d, i, list);
            });
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Message sent',
              })
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Something went wrong',
              })
            );
          }
          console.log(e);
        },
        dispatch
      );
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'All Fields required',
        })
      );
    }
  };
  console.log(templateDetails);

  const sendMessage = async () => {
    const imageID = Date.now();

    if (capturedImage || selectedFile) {
      dispatch(setSpinnerLoading('Uploading Image'));
      const camera = capturedImage
        ? await fetch(capturedImage).then((response) => response.blob())
        : '';
      const file = selectedFile ? selectedFile : camera;

      const name = imageID;
      const path = 'rootbilling/promoImage/';
      UploadFile(file, path, name).then(() => {
        const path = `rootbilling/promoImage/${imageID}`;
        DownloadFile(path)
          .then((e) => {
            dispatch(setSpinnerLoading('Making list'));
            if (!e.includes('FirebaseError')) {
              const s = e.split(
                'https://firebasestorage.googleapis.com/v0/b/genius-office.appspot.com/'
              );
              const variables = {
                1: s[1],
              };

              save(s, variables);
            } else {
              setFirebasePic('');
              setPath('');
            }
          })
          .catch((e) => {
            console.log(e);
            // setCapturedImage('');
            setFirebasePic('');
          });
      });
    } else {
      if (templateDetails[0].message_type !== 'Media') {
        save(['111', '22']);
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Please select image',
          })
        );
      }
    }
  };

  useEffect(() => {
    loadCustomerDetails();
  }, [productName]);

  useEffect(() => {
    setSendMsgData((e) =>
      customerDetails.map((old) => {
        return {
          DATE: moment().format('YYYY-MM-DD'),
          CODE: old.CODE,
          NAME: old.NAME,
          COMPANY_NAME: old.COMPANY_NAME,
          MSG_MOBILE: old.MSG_MOBILE,
          PRODUCT_NAME: old.PRODUCT_NAME,
          TEMPLATE_ID: templateID,
          MESSAGE: templateDetails[0] ? templateDetails[0]?.template_text : '',
          MESSAGE_TYPE: messagetype,
          IMAGE_ID: 'TX32DDD',
        };
      })
    );
  }, [customerDetails, templateID, messagetype]);

  useEffect(() => {
    listPromoMsg();
  }, [messagesListDate]);
  useEffect(() => {
    if (templateID) {
      const Details = templatelist.filter((t) => t.template_id === templateID);
      const variable = Details[0]
        ? JSON.parse(Details[0].template_variable)
        : [];
      setTamplateDetails(Details);
      setTemplateVariablesValues(
        variable.map((v, i) => {
          return {
            name: v,
            value: '',
          };
        })
      );
    }
    templatelist.filter((e) => e.template_id === templateID);
  }, [templateID]);
  useEffect(() => {
    CallAPIPromise(
      '/api/userSettings/messageTemplateList',
      {
        company_email: companyDetails.email,
        template_id: '',
      },
      token,
      dispatch
    ).then((res) => {
      if (res?.data?.response) {
        setTemplatelist(res.data.response);
      }
    });
  }, []);
  return (
    <div
      ref={formRef}
      className="w-full h-full flex justify-center bg-[#f5f5f7] p-8 overflow-y-scroll"
    >
      <div className="text-sm  rounded-sm  h-full">
        <div className="bg-white   ">
          <ModalHeaderStyle
            header={'Send Promo Message'}
            onClose={() => {
              navigate('/');
            }}
          />
        </div>
        <div
          style={{
            height: `calc(100% - ${50}px)`,
          }}
          className="      flex flex-col overflow-y-auto bg-white p-2 "
        >
          <div className=" w-full  rounded-sm flex ">
            <div
              onClick={() => {
                setTabScreen(1);
              }}
              style={{
                color: tabScreen === 1 ? 'white' : 'gray',
              }}
              className={`flex-1 p-2 ${
                tabScreen === 1
                  ? 'bg-[#0d0d0d] '
                  : ' hover:bg-[#f1f5f9]  bg-[#ebe8e8] hover:cursor-pointer '
              }  flex justify-center items-center rounded-l-sm `}
            >
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: tabScreen === 1 ? 600 : 400,
                }}
                className=" h-full flex items-center"
              >
                Send Message
              </div>
            </div>
            <div
              style={{
                color: tabScreen === 2 ? 'white' : 'gray',
              }}
              onClick={() => {
                setTabScreen(2);
              }}
              className={`flex-1 p-2 ${
                tabScreen === 2
                  ? 'bg-[#0d0d0d] '
                  : ' hover:bg-[#f1f5f9] bg-[#ebe8e8] hover:cursor-pointer '
              }  flex justify-center items-center rounded-r-sm `}
            >
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: tabScreen === 2 ? 600 : 400,
                }}
                className="  flex items-center"
              >
                Report
              </div>
            </div>
          </div>
          <div
            style={{
              height: `calc(100% - 10px)`,
            }}
            className=" flex flex-col overflow-y-auto mt-4 overflow-x-hidden "
          >
            <div className="h-full">
              {tabScreen === 1 ? (
                <div className="p-2 h-full">
                  <div className="font-[600] text-brown">Customer Details</div>

                  <div className="flex">
                    <GeniustextInput
                      title={'Product Name'}
                      backgroundColor={'#f1efeb'}
                      width={'200px'}
                      option={products}
                      value={productName}
                      onChange={(e) => setproductName(e.target.value)}
                    />
                    <div className="ml-2">
                      <GeniustextInput
                        title={'Template Id'}
                        backgroundColor={'#f1efeb'}
                        width={'290px'}
                        option={templatelist.map((t) => {
                          return {
                            name: t.template_for,
                            value: t.template_id,
                          };
                        })}
                        value={templateID}
                        onChange={(e) => setTemplateID(e.target.value)}
                      />
                    </div>
                    <div className="ml-2">
                      <GeniustextInput
                        title={'Message Type'}
                        backgroundColor={'#f1efeb'}
                        width={'200px'}
                        option={[
                          {
                            name: 'WhatsApp',
                            value: 'WhatsApp',
                          },
                          {
                            name: 'SMS',
                            value: 'SMS',
                          },
                        ]}
                        onChange={(e) => setMesageType(e.target.value)}
                        value={messagetype}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      height: `calc(100% - 350px)`,
                      width:
                        columns.reduce(
                          (acc, row) => acc + Number(row.width),
                          0
                        ) + 20,
                    }}
                  >
                    <GeniusDatagrid
                      rowID={(r) => r.CODE}
                      columns={columns}
                      list={customerDetails}
                    />
                  </div>
                  <div className="flex mt-2 justify-between">
                    <div className="">
                      <div>
                        <GeniustextInput
                          title={'Message'}
                          value={templateDetails[0]?.template_text}
                          textArea={7}
                          readOnly={true}
                          width={'500px'}
                        />
                        <div>
                          {templateVariablesValues.map((t, i) => {
                            return (
                              <GeniustextInput
                                title={t.name}
                                value={t.value}
                                onChange={(e) => {
                                  setTemplateVariablesValues((o) =>
                                    o.map((v, vIndex) => {
                                      if (i === vIndex) {
                                        return {
                                          ...t,
                                          value: e.target.value,
                                        };
                                      } else {
                                        return v;
                                      }
                                    })
                                  );
                                }}
                                type={'text'}
                              />
                            );
                          })}
                        </div>
                        <div className="flex  justify-end ">
                          <div>
                            <SendGreenButton onClick={sendMessage} />
                          </div>
                          <div>
                            <UndoButton
                              onClick={() => {
                                setSelectedSendMessageList([]);
                                setSelectedFile('');
                                setCapturedImage('');
                                setproductName(products[0].name);
                                setTemplateID('');
                                setMesageType('WhatsApp');
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {templateDetails[0]?.message_type === 'Media' && (
                      <div>
                        <div
                          style={{
                            border: '1px solid #b5b3b3',
                            width: '150px',
                            height: '150px',
                          }}
                          className="p-2 rounded-sm"
                        >
                          <ProfilePicFrame
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            webcamRef={webcamRef}
                            webcamStatus={webcamStatus}
                            capturedImage={capturedImage}
                            setCapturedImage={setCapturedImage}
                            setWebcamStatus={setWebcamStatus}
                            customerFirebasePic={firebsaePic}
                            path={path}
                            setFirebaseUrl={setFirebasePic}
                          />
                        </div>
                        <div
                          className="flex mt-2"
                          style={{
                            width: '150px',
                          }}
                        >
                          <UploadImageButtons
                            title={'Select Image'}
                            setSelectedFile={setSelectedFile}
                            webcamStatus={webcamStatus}
                            setWebcamStatus={setWebcamStatus}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="p-2 h-full  ">
                  <div className="border-b mt-3" />
                  <div className="font-[600] mt-3 text-brown">
                    {' '}
                    Message Transactions
                  </div>

                  <div className="flex mt-2">
                    <div className="mt-[6px]">Date</div>
                    <div className="ml-2">
                      <GeniustextInput
                        date={true}
                        fromDateFocus={msgListDateFocus}
                        hideHeader={true}
                        setFromDateFocus={setMsgListDateFocus}
                        setFromDate={setMsgListDate}
                        fromDate={messagesListDate}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      height: `300px`,
                    }}
                  >
                    <GeniusDatagrid columns={columns2} list={sendMessageList} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendPromoMsg;
