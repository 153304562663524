import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { CallAPIPromise } from '../routeBilling/menu/dashboard/biiling/modal/comman';
import { useDispatch } from 'react-redux';

const ActivationAccount = () => {
  const searchParams = new URLSearchParams(window.location.search);
  // const param1 = searchParams.get('company_email');
  const userEmail = searchParams.get('user_email');
  const [userStatus, setUserStatus] = useState(' in Process');
  const activationCode = searchParams.get('activate_code');
  const userMobile = searchParams.get('user_mobile');
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const u = userEmail ? userEmail : userMobile;
  // const hmac = CryptoJS.HmacSHA256(message, secret);
  const hmac = CryptoJS.HmacSHA256(u, secretPass);
  // Convert the HMAC to a string and encode it using Base64
  const hmacString = hmac.toString(CryptoJS.enc.Base64);
  const dispacth = useDispatch();
  const ActivateUser = () => {
    const data = {
      user_email: userEmail,
      user_mobile: !userEmail ? userMobile : '',
    };
    CallAPIPromise(`/api/users/userInfo`, data, hmacString, dispacth)
      .then(async function (response) {
        if (response.data.response[0]) {
          CallAPIPromise(
            '/api/users/activateUser',
            {
              user_email: userEmail,
              activate_code: activationCode,
              user_name: response.data.response[0].user_name,
              user_mobile: !userEmail ? userMobile : '',
              code: response.data.response[0].code,
            },
            hmacString,
            dispacth
          )
            .then((d) => {
              setUserStatus('activated');
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (activationCode) {
      ActivateUser();
    }
  }, []);

  return (
    <div className="h-screen w-screen p-4"> {`Account is ${userStatus}`}</div>
  );
};

export default ActivationAccount;
