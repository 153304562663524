import React from 'react';
import GeniusError from './styles/error';
import { useState } from 'react';
import { Checkbox } from '@mui/material';
import GeniusSelectDate from './selectDate';
import { Close } from '@mui/icons-material';
import './textStyles.module.css';
import GeniusTimePicker from './selectTime';
// import '/node_modules/flag-icons/css/flag-icons.min.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const GeniustextInput = ({
  title,
  name,
  custome,
  grid,
  onClear,
  inputButton,
  titleButton,
  fromDate,
  id,
  onBlur,
  titleAlign,
  currency,
  readOnly,
  reset,
  onKeyDown,
  height,
  areaRows,
  setFromDate,
  fromDateFocus,
  setFromDateFocus,
  search,
  type,
  option,
  width,
  time,
  selectedTime,
  onChangeTime,
  SecondTitle,
  max,
  value,
  titleBold,
  onFocus,
  date,
  backgroundColor,
  onChange,
  error,
  refrance,
  onClick,
  hideError,
  country,
  color,
  titleColor,
  checkBox,
  placeholder,
  autofocus,
  checked,
  hideHeader,
  secondText,
  fontBold,
  align,
  iconAlign,
  textArea,
  rightIcon,
}) => {
  const [border, setBorder] = useState('#b5b3b3');
  const phoneRegex = /^[6-9]\d{9}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return (
    <div className="text-[16px] ll:text-[15px]  ">
      {!titleAlign && (
        <div
          style={{
            height: !checkBox ? '24px' : 'auto',
            display: hideHeader ? 'none' : 'flex',
          }}
          className="flex  justify-between flex-1 "
        >
          <div className="flex items-center w-fit  ">
            {title ? (
              <div
                style={{
                  fontWeight: titleBold ? 700 : 500,
                  color: titleColor ? titleColor : 'black',
                }}
                className="flex  "
              >
                <div>{title}</div>
              </div>
            ) : (
              '   '
            )}
            {search && (
              <button onClick={search}>
                <i className="bi bi-search text-orange ml-2 flex items-center"></i>
              </button>
            )}
          </div>
          <div>{titleButton ? titleButton : ''}</div>

          {SecondTitle && <SecondTitle />}
        </div>
      )}
      <div className="flex items-center flex-1 ">
        {titleAlign && titleAlign === 'left' && (
          <div
            style={{
              height: !checkBox ? '24px' : 'auto',
              display: hideHeader ? 'none' : 'flex',
            }}
            className="flex  justify-between  mr-3"
          >
            <div className="flex items-center w-fit   ">
              {title ? (
                <div
                  style={{
                    fontWeight: titleBold ? 600 : 500,
                    color: titleColor ? titleColor : 'black',
                  }}
                >
                  {' '}
                  {title}
                </div>
              ) : (
                '   '
              )}
            </div>
            {SecondTitle && <SecondTitle />}
          </div>
        )}
        <div
          style={
            checkBox || date
              ? !grid
                ? {
                    border: `0px solid ${border}`,
                    boxShadow:
                      border === '#43bff7' && '0px 0px 0px 0px #cbeaf7',
                    padding: '0px',
                    width: date ? width : 'auto',
                  }
                : {
                    border: `0px solid ${border}`,
                    boxShadow:
                      border === '#43bff7' && '0px 0px 0px 0px #cbeaf7',
                    padding: '0px',
                    flex: 1,
                  }
              : !grid
              ? {
                  border: !custome ? `1px solid ${border}` : 'none',
                  width: width,
                  boxShadow: border === '#43bff7' && '0px 0px 0px 1px #cbeaf7',
                  backgroundColor: backgroundColor ? backgroundColor : 'white',
                }
              : {
                  border: !custome ? `1px solid ${border}` : 'none',
                  flex: 1,
                  boxShadow: !custome
                    ? border === '#43bff7' && '0px 0px 0px 1px #cbeaf7'
                    : 'none',
                  backgroundColor: backgroundColor ? backgroundColor : 'white',
                }
          }
          className="rounded-sm overflow-hidden   "
        >
          {type && (
            <div className="flex items-center h-[38px] flex-1 ">
              {secondText && (
                <input
                  type={'text'}
                  readOnly={true}
                  style={{
                    outline: 'none',
                    color: '#4a4a4a',
                    borderRight: '1px solid #b5b3b3',
                    paddingLeft: '6px',
                    backgroundColor: secondText.backgroundColor
                      ? secondText.backgroundColor
                      : 'white',
                    fontWeight: fontBold ? fontBold : 500,
                    width: secondText.width ? secondText.width : '60px',
                  }}
                  onBlur={() => {
                    if (error === 'mobile' || error === 'email' || !error) {
                      if (error === 'mobile') {
                        if (value) {
                          if (phoneRegex.test(value)) {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          setBorder('#b5b3b3');
                        }
                      } else {
                        if (error === 'email') {
                          if (value) {
                            if (emailRegex.test(value)) {
                              setBorder('#b5b3b3');
                            }
                          } else {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          if (value) {
                            if (!error) {
                              setBorder('#b5b3b3');
                            }
                          } else {
                            setBorder('#b5b3b3');
                          }
                        }
                      }
                    }
                  }}
                  className="w-[50px]  p-[6px]"
                  value={secondText.value}
                />
              )}
              {type !== 'countryMobile' ? (
                <input
                  id={id ? id : ''}
                  type={type}
                  readOnly={readOnly ? true : false}
                  name={name}
                  style={{
                    outline: 'none',
                    color: '#4a4a4a',
                    backgroundColor: backgroundColor
                      ? backgroundColor
                      : '#fdfffe',
                    width: '100%',
                    textAlign: align ? align : 'left',
                    fontWeight: fontBold ? fontBold : 500,
                  }}
                  ref={refrance}
                  onClick={onClick}
                  onFocus={
                    onFocus
                      ? onFocus
                      : () => {
                          if (
                            !error ||
                            error === 'mobile' ||
                            error === 'email'
                          ) {
                            setBorder('#43bff7');
                          }
                        }
                  }
                  onBlur={() => {
                    if (error === 'mobile' || error === 'email' || !error) {
                      if (error === 'mobile') {
                        if (value) {
                          if (phoneRegex.test(value)) {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          setBorder('#b5b3b3');
                        }
                      } else {
                        if (error === 'email') {
                          if (value) {
                            if (emailRegex.test(value)) {
                              setBorder('#b5b3b3');
                            }
                          } else {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          if (value) {
                            if (!error) {
                              setBorder('#b5b3b3');
                            }
                          } else {
                            setBorder('#b5b3b3');
                          }
                        }
                      }
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                      event.preventDefault();
                    }
                    if (onKeyDown) {
                      onKeyDown(event);
                    }
                  }}
                  value={value}
                  max={max ? max : 100}
                  maxLength={max ? max : 100}
                  onChange={(e) => {
                    if (max && type === 'number') {
                      if (e.target.value.length <= max) {
                        onChange(e);
                      }
                    } else {
                      onChange(e);
                    }
                  }}
                  placeholder={placeholder}
                  autoFocus={autofocus}
                  className="p-2 outline-none  texrt[15px]"
                />
              ) : (
                <PhoneInput
                  country={'in'} // Default country
                  containerStyle={{
                    backgroundColor: 'pink',
                    width: '200px',
                  }}
                  inputStyle={{
                    width: '290px',
                    backgroundColor: '#f1efeb',
                    height: '40px',
                  }}
                  value={value}
                  onChange={onChange}
                  enableSearch={true} // Enable search for countries
                  disableSearchIcon={true} // Optional: disable the search icon
                />
              )}

              {onClear && value && (
                <button onClick={onClear}>
                  <i className="bi text-red-500 bi-x-lg"></i>
                </button>
              )}
              {rightIcon && rightIcon}
              {reset && value && (
                <div className="mr-1">
                  <Close
                    fontSize={'small'}
                    sx={{
                      color: 'red',
                    }}
                    onClick={reset}
                  />{' '}
                </div>
              )}

              {inputButton && inputButton}
            </div>
          )}
          {textArea && (
            <div
              style={{
                height: height ? height : 'auto',
              }}
              className="flex  items-center"
            >
              {' '}
              <textarea
                id={id}
                type={type}
                readOnly={readOnly ? true : false}
                rows={areaRows ? areaRows : 4}
                name={name}
                style={{
                  outline: 'none',
                  color: '#4a4a4a',
                  backgroundColor: backgroundColor ? backgroundColor : 'white',
                  fontWeight: fontBold ? fontBold : 500,
                  width: '100%',
                  textAlign: align ? align : 'left',
                }}
                ref={refrance}
                onClick={onClick}
                onFocus={
                  onFocus
                    ? onFocus
                    : () => {
                        if (!error || error === 'mobile' || error === 'email') {
                          setBorder('#03A9F4');
                        }
                      }
                }
                onBlur={() => {
                  if (error === 'mobile' || error === 'email' || !error) {
                    if (error === 'mobile') {
                      if (value) {
                        if (phoneRegex.test(value)) {
                          setBorder('#b5b3b3');
                        }
                      } else {
                        setBorder('#b5b3b3');
                      }
                    } else {
                      if (error === 'email') {
                        if (value) {
                          if (emailRegex.test(value)) {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          setBorder('#b5b3b3');
                        }
                      } else {
                        if (value) {
                          if (!error) {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          setBorder('#b5b3b3');
                        }
                      }
                    }
                  }
                }}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                autoFocus={autofocus}
                className="p-2"
              />{' '}
              {reset && value && (
                <div className="mr-1">
                  {' '}
                  <Close
                    fontSize={'small'}
                    sx={{ color: 'red' }}
                    onClick={reset}
                  />{' '}
                </div>
              )}{' '}
            </div>
          )}
          {option && !country ? (
            <div className="h-[38px]">
              <select
                value={value}
                className="p-[6px]"
                onChange={onChange}
                name={name}
                style={{
                  fontSize: window.innerWidth <= 1280 ? '16px' : '15px',
                  outline: 'none',
                  width: '100%',
                  backgroundColor: '#fdfffe',
                  textAlign: 'left',
                  height: '100%',
                }}
              >
                <option value={''} label="Select"></option>
                {option.map((e, i) => {
                  return (
                    <option key={i} value={e.value}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}
          {country && (
            <div className="h-[38px]">
              <select
                className="p-[6px]"
                value={value}
                onChange={onChange}
                style={{
                  fontSize: window.innerWidth <= 1280 ? '16px' : '14px',
                  outline: 'none',
                  width: '100%',
                  backgroundColor: 'transparent',
                  textAlign: 'left',
                }}
              >
                {option.map((e, k) => {
                  return (
                    <option key={k} value={e.name}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          {currency && (
            <div className="h-[38px]">
              <select
                className="p-[6px]"
                value={value}
                onChange={onChange}
                style={{
                  fontSize: window.innerWidth <= 1280 ? '16px' : '14px',
                  outline: 'none',
                  width: '100%',
                  backgroundColor: 'transparent',
                  textAlign: 'left',
                }}
              >
                {currency.map((e, k) => {
                  return (
                    <option key={k} value={e.name}>
                      <span class="fi fi-gr bg-red-200"></span>{' '}
                      <span class="fi fi-gr fis"></span>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          {custome && custome}

          {date && (
            <div className="h-[38px] flex-1 ">
              <GeniusSelectDate
                date={fromDate}
                border={border}
                grid={grid}
                audtoFocus={autofocus}
                setDate={setFromDate}
                dateFocus={fromDateFocus}
                SetDateFocus={setFromDateFocus}
              />
            </div>
          )}
          {time && (
            <GeniusTimePicker
              selectedTime={selectedTime}
              onChangeTime={onChangeTime}
            />
          )}

          {checkBox ? (
            <Checkbox
              size="small"
              sx={{ border: '0px' }}
              checked={checked}
              onChange={onChange}
              color={color ? 'primary' : 'secondary'}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          ) : null}
        </div>
        {iconAlign && iconAlign === 'right' && search && (
          <button onClick={search}>
            <i className="bi bi-search text-orange ml-2 flex items-center"></i>
          </button>
        )}
      </div>

      {!hideError ? (
        <GeniusError
          error={
            error === 'mobile'
              ? !value || phoneRegex.test(value)
                ? false
                : 'Invalid mobile'
              : error === 'email'
              ? !value || emailRegex.test(value)
                ? false
                : 'Invalid Email'
              : error
          }
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default GeniustextInput;
